export const validator = {
    hasError: true,
    company_id: {
        error_show: false,
        error_msg: null
    },
    payment_type: {
        error_show: false,
        error_msg: null
    },
    amount: {
        error_show: false,
        error_msg: null
    },
    payed_from: {
        error_show: false,
        error_msg: null
    },
    payed_to: {
        error_show: false,
        error_msg: null
    },
    next_payment_amount: {
        error_show: false,
        error_msg: null
    },
    barcode_count: {
        error_show: false,
        error_msg: null
    },
    note: {
        error_show: false,
        error_msg: null
    },
}

export const newItem = {
    company_id: null,
    payment_type: null,
    amount: null,
    payed_from: null,
    payed_to: null,
    next_payment_amount: null,
    barcode_count: null,
    note: null
}
