let countries = {
    getList() {
        let list = [
            { "text": { "en": "Georgia", "ka": "საქართველო" }, "code": "GE" },
            { "text": { "en": "Afghanistan", "ka": "ავღანეთი" }, "code": "AF" },
            { "text": { "en": "Åland Islands", "ka": "ალანდის კუნძულები" }, "code": "AX" },
            { "text": { "en": "Albania", "ka": "ალბანეთი" }, "code": "AL" },
            { "text": { "en": "Algeria", "ka": "ალგერია" }, "code": "DZ" },
            { "text": { "en": "American Samoa", "ka": "ამერიკული სამოა" }, "code": "AS" },
            { "text": { "en": "Andorra", "ka": "ანდორა" }, "code": "AD" },
            { "text": { "en": "Angola", "ka": "ანგოლა" }, "code": "AO" },
            { "text": { "en": "Anguilla", "ka": "ანგილია" }, "code": "AI" },
            { "text": { "en": "Antarctica", "ka": "ანტარქტიდა" }, "code": "AQ" },
            { "text": { "en": "Antigua and Barbuda", "ka": "ანტიგუა და ბარბუდა" }, "code": "AG" },
            { "text": { "en": "Argentina", "ka": "ანტიგუა და ბარბუდა" }, "code": "AR" },
            { "text": { "en": "Armenia", "ka": "სომხეთი" }, "code": "AM" },
            { "text": { "en": "Aruba", "ka": "არუბა" }, "code": "AW" },
            { "text": { "en": "Australia", "ka": "ავსტრალია" }, "code": "AU" },
            { "text": { "en": "Austria", "ka": "ავსტრია" }, "code": "AT" },
            { "text": { "en": "Azerbaijan", "ka": "აზერბაიჯანი" }, "code": "AZ" },
            { "text": { "en": "Bahamas", "ka": "ბაჰამის კუნძულები" }, "code": "BS" },
            { "text": { "en": "Bahrain", "ka": "ბაჰრეინი" }, "code": "BH" },
            { "text": { "en": "Bangladesh", "ka": "ბანგლადეში" }, "code": "BD" },
            { "text": { "en": "Barbados", "ka": "ბარბადოსი" }, "code": "BB" },
            { "text": { "en": "Belarus", "ka": "ბელარუსი" }, "code": "BY" },
            { "text": { "en": "Belgium", "ka": "ბელგია" }, "code": "BE" },
            { "text": { "en": "Belize", "ka": "ბელიზი" }, "code": "BZ" },
            { "text": { "en": "Benin", "ka": "ბენინი" }, "code": "BJ" },
            { "text": { "en": "Bermuda", "ka": "ბერმუდის კუნძულები" }, "code": "BM" },
            { "text": { "en": "Bhutan", "ka": "ბუტანი" }, "code": "BT" },
            { "text": { "en": "Bolivia", "ka": "ბოლივია" }, "code": "BO" },
            { "text": { "en": "Bosnia and Herzegovina", "ka": "ბოსნია და ჰერცეგოვინა" }, "code": "BA" },
            { "text": { "en": "Botswana", "ka": "ბოცვანა" }, "code": "BW" },
            { "text": { "en": "Bouvet Island", "ka": "ბუვე" }, "code": "BV" },
            { "text": { "en": "Brazil", "ka": "ბრაზილია" }, "code": "BR" },
            { "text": { "en": "British Indian Ocean Territory", "ka": "ბრიტანეთის ინდოეთის ოკეანის ტერიტორია" }, "code": "IO" },
            { "text": { "en": "Brunei Darussalam", "ka": "ბრუნეი დარუსალამი" }, "code": "BN" },
            { "text": { "en": "Bulgaria", "ka": "ბულგარეთი" }, "code": "BG" },
            { "text": { "en": "Burkina Faso", "ka": "ბურკინა ფასო" }, "code": "BF" },
            { "text": { "en": "Burundi", "ka": "ბურუნდი" }, "code": "BI" },
            { "text": { "en": "Cambodia", "ka": "კამბოჯა" }, "code": "KH" },
            { "text": { "en": "Cameroon", "ka": "კამერუნი" }, "code": "CM" },
            { "text": { "en": "Canada", "ka": "კანადა" }, "code": "CA" },
            { "text": { "en": "Cape Verde", "ka": "კაბო-ვერდე" }, "code": "CV" },
            { "text": { "en": "Cayman Islands", "ka": "კაიმანის კუნძულები" }, "code": "KY" },
            { "text": { "en": "Central African Republic", "ka": "ცენტრალური აფრიკის რესპუბლიკა" }, "code": "CF" },
            { "text": { "en": "Chad", "ka": "ცენტრალური აფრიკის რესპუბლიკა" }, "code": "TD" },
            { "text": { "en": "Chile", "ka": "ჩილე" }, "code": "CL" },
            { "text": { "en": "China", "ka": "ჩინეთი" }, "code": "CN" },
            { "text": { "en": "Christmas Island", "ka": "შობის კუნძული" }, "code": "CX" },
            { "text": { "en": "Cocos (Keeling) Islands", "ka": "ქოქოსის კუნძულები" }, "code": "CC" },
            { "text": { "en": "Colombia", "ka": "კოლუმბია" }, "code": "CO" },
            { "text": { "en": "Comoros", "ka": "კომორის კუნძულები" }, "code": "KM" },
            { "text": { "en": "Congo", "ka": "კონგოს რესპუბლიკა" }, "code": "CG" },
            { "text": { "en": "Congo, The Democratic Republic of the", "ka": "კონგოს დემოკრატიული რესპუბლიკა" }, "code": "CD" },
            { "text": { "en": "Cook Islands", "ka": "კუკის კუნძულები" }, "code": "CK" },
            { "text": { "en": "Costa Rica", "ka": "კოსტა-რიკა" }, "code": "CR" },
            { "text": { "en": "Cote D'Ivoire", "ka": "კოტ-დივუარი" }, "code": "CI" },
            { "text": { "en": "Croatia", "ka": "ხორვატია" }, "code": "HR" },
            { "text": { "en": "Cuba", "ka": "კუბა" }, "code": "CU" },
            { "text": { "en": "Cyprus", "ka": "კვიპროსი" }, "code": "CY" },
            { "text": { "en": "Czech Republic", "ka": "ჩეხეთის რესპუბლიკა" }, "code": "CZ" },
            { "text": { "en": "Denmark", "ka": "დანია" }, "code": "DK" },
            { "text": { "en": "Djibouti", "ka": "ჯიბუტი" }, "code": "DJ" },
            { "text": { "en": "Dominica", "ka": "დომინიკა" }, "code": "DM" },
            { "text": { "en": "Dominican Republic", "ka": "დომინიკის რესპუბლიკა" }, "code": "DO" },
            { "text": { "en": "Ecuador", "ka": "ეკვადორი" }, "code": "EC" },
            { "text": { "en": "Egypt", "ka": "ეგვიპტე" }, "code": "EG" },
            { "text": { "en": "El Salvador", "ka": "ელ სალვადორი" }, "code": "SV" },
            { "text": { "en": "Equatorial Guinea", "ka": "ეკვატორული გვინეა" }, "code": "GQ" },
            { "text": { "en": "Eritrea", "ka": "ერითრეა" }, "code": "ER" },
            { "text": { "en": "Estonia", "ka": "ესტონეთი" }, "code": "EE" },
            { "text": { "en": "Ethiopia", "ka": "ეთიოპია" }, "code": "ET" },
            { "text": { "en": "Falkland Islands (Malvinas)", "ka": "ფოლკლენდის კუნძულები" }, "code": "FK" },
            { "text": { "en": "Faroe Islands", "ka": "ფარერის კუნძულები" }, "code": "FO" },
            { "text": { "en": "Fiji", "ka": "ფიჯი" }, "code": "FJ" },
            { "text": { "en": "Finland", "ka": "ფინეთი" }, "code": "FI" },
            { "text": { "en": "France", "ka": "საფრანგეთი" }, "code": "FR" },
            { "text": { "en": "French Guiana", "ka": "ფრანგული გვინეა" }, "code": "GF" },
            { "text": { "en": "French Polynesia", "ka": "ფრანგული პოლინეზია" }, "code": "PF" },
            { "text": { "en": "French Southern Territories", "ka": "საფრანგეთის სამხრეთული და ანტარქტიდული ტერიტორია" }, "code": "TF" },
            { "text": { "en": "Gabon", "ka": "გაბონი" }, "code": "GA" },
            { "text": { "en": "Gambia", "ka": "გამბია" }, "code": "GM" },
            { "text": { "en": "Germany", "ka": "გერმანია" }, "code": "DE" },
            { "text": { "en": "Ghana", "ka": "განა" }, "code": "GH" },
            { "text": { "en": "Gibraltar", "ka": "გიბრალტარი" }, "code": "GI" },
            { "text": { "en": "Greece", "ka": "საბერძნეთი" }, "code": "GR" },
            { "text": { "en": "Greenland", "ka": "გრენლანდია" }, "code": "GL" },
            { "text": { "en": "Grenada", "ka": "გრენადა" }, "code": "GD" },
            { "text": { "en": "Guadeloupe", "ka": "გვადალუპე" }, "code": "GP" },
            { "text": { "en": "Guam", "ka": "გუამი" }, "code": "GU" },
            { "text": { "en": "Guatemala", "ka": "გვატემალა" }, "code": "GT" },
            { "text": { "en": "Guernsey", "ka": "გერნსი" }, "code": "GG" },
            { "text": { "en": "Guinea", "ka": "გვინეა" }, "code": "GN" },
            { "text": { "en": "Guinea-Bissau", "ka": "გვინეა-ბისაუ" }, "code": "GW" },
            { "text": { "en": "Guyana", "ka": "გაიანა" }, "code": "GY" },
            { "text": { "en": "Haiti", "ka": "ჰაიტი" }, "code": "HT" },
            { "text": { "en": "Heard Island and Mcdonald Islands", "ka": "ჰერდი და მაკდონალდის კუნძულები" }, "code": "HM" },
            { "text": { "en": "Holy See (Vatican City State)", "ka": "ვატიკანი" }, "code": "VA" },
            { "text": { "en": "Honduras", "ka": "ჰონდურასი" }, "code": "HN" },
            { "text": { "en": "Hong Kong", "ka": "ჰონგკონგი" }, "code": "HK" },
            { "text": { "en": "Hungary", "ka": "უნგრეთი" }, "code": "HU" },
            { "text": { "en": "Iceland", "ka": "ისლანდია" }, "code": "IS" },
            { "text": { "en": "India", "ka": "ინდოეთი" }, "code": "IN" },
            { "text": { "en": "Indonesia", "ka": "ინდონეზია" }, "code": "ID" },
            { "text": { "en": "Iran, Islamic Republic Of", "ka": "ირანი" }, "code": "IR" },
            { "text": { "en": "Iraq", "ka": "ერაყი" }, "code": "IQ" },
            { "text": { "en": "Ireland", "ka": "ირლანდია" }, "code": "IE" },
            { "text": { "en": "Isle of Man", "ka": "მენი" }, "code": "IM" },
            { "text": { "en": "Israel", "ka": "ისრაელი" }, "code": "IL" },
            { "text": { "en": "Italy", "ka": "იტალია" }, "code": "IT" },
            { "text": { "en": "Jamaica", "ka": "იამაიკა" }, "code": "JM" },
            { "text": { "en": "Japan", "ka": "იაპონია" }, "code": "JP" },
            { "text": { "en": "Jersey", "ka": "ჯერსი" }, "code": "JE" },
            { "text": { "en": "Jordan", "ka": "იორდანია" }, "code": "JO" },
            { "text": { "en": "Kazakhstan", "ka": "ყაზახეთი" }, "code": "KZ" },
            { "text": { "en": "Kenya", "ka": "კენია" }, "code": "KE" },
            { "text": { "en": "Kiribati", "ka": "კირიბატი" }, "code": "KI" },
            { "text": { "en": "Korea, Democratic People'S Republic of", "ka": "ჩრდილოეთი კორეა" }, "code": "KP" },
            { "text": { "en": "Korea, Republic of", "ka": "სამხრეთი კორეა" }, "code": "KR" },
            { "text": { "en": "Kuwait", "ka": "ქუვეითი" }, "code": "KW" },
            { "text": { "en": "Kyrgyzstan", "ka": "ყირგიზეთი" }, "code": "KG" },
            { "text": { "en": "Lao People'S Democratic Republic", "ka": "ლაოს დემოკრატიული რესპუბლიკა" }, "code": "LA" },
            { "text": { "en": "Latvia", "ka": "ლატვია" }, "code": "LV" },
            { "text": { "en": "Lebanon", "ka": "ლიბანი" }, "code": "LB" },
            { "text": { "en": "Lesotho", "ka": "ლესოთო" }, "code": "LS" },
            { "text": { "en": "Liberia", "ka": "ლიბერია" }, "code": "LR" },
            { "text": { "en": "Libyan Arab Jamahiriya", "ka": "ლიბია" }, "code": "LY" },
            { "text": { "en": "Liechtenstein", "ka": "ლიხტენშტეინი" }, "code": "LI" },
            { "text": { "en": "Lithuania", "ka": "ლიტვა" }, "code": "LT" },
            { "text": { "en": "Luxembourg", "ka": "ლუქსემბურგი" }, "code": "LU" },
            { "text": { "en": "Macao", "ka": "მაკაო" }, "code": "MO" },
            { "text": { "en": "Macedonia, The Former Yugoslav Republic of", "ka": "მაკედონია" }, "code": "MK" },
            { "text": { "en": "Madagascar", "ka": "მადაგასკარი" }, "code": "MG" },
            { "text": { "en": "Malawi", "ka": "მალავი" }, "code": "MW" },
            { "text": { "en": "Malaysia", "ka": "მალაიზია" }, "code": "MY" },
            { "text": { "en": "Maldives", "ka": "მადივი" }, "code": "MV" },
            { "text": { "en": "Mali", "ka": "მალი" }, "code": "ML" },
            { "text": { "en": "Malta", "ka": "მატლა" }, "code": "MT" },
            { "text": { "en": "Marshall Islands", "ka": "მარშალის კუნძულები" }, "code": "MH" },
            { "text": { "en": "Martinique", "ka": "მარტინიკა" }, "code": "MQ" },
            { "text": { "en": "Mauritania", "ka": "მავრიტანია" }, "code": "MR" },
            { "text": { "en": "Mauritius", "ka": "მავრიკი" }, "code": "MU" },
            { "text": { "en": "Mayotte", "ka": "მაიოტა" }, "code": "YT" },
            { "text": { "en": "Mexico", "ka": "მექსიკა" }, "code": "MX" },
            { "text": { "en": "Micronesia, Federated States of", "ka": "მიკრონეზიის ფედერაციული შტატები" }, "code": "FM" },
            { "text": { "en": "Moldova, Republic of", "ka": "მოლდოვეთი" }, "code": "MD" },
            { "text": { "en": "Monaco", "ka": "მონაკო" }, "code": "MC" },
            { "text": { "en": "Mongolia", "ka": "მონღოლეთი" }, "code": "MN" },
            { "text": { "en": "Montserrat", "ka": "მონსერატი" }, "code": "MS" },
            { "text": { "en": "Morocco", "ka": "მაროკო" }, "code": "MA" },
            { "text": { "en": "Mozambique", "ka": "მოზამბიკი" }, "code": "MZ" },
            { "text": { "en": "Myanmar", "ka": "მიანმარი" }, "code": "MM" },
            { "text": { "en": "Namibia", "ka": "ნამიბია" }, "code": "NA" },
            { "text": { "en": "Nauru", "ka": "ნაურუ" }, "code": "NR" },
            { "text": { "en": "Nepal", "ka": "ნეპალი" }, "code": "NP" },
            { "text": { "en": "Netherlands", "ka": "ნიდერლანდები" }, "code": "NL" },
            { "text": { "en": "Netherlands Antilles", "ka": "ნიდერლანდის კუნძულები" }, "code": "AN" },
            { "text": { "en": "New Caledonia", "ka": "ახალი კალედონია" }, "code": "NC" },
            { "text": { "en": "New Zealand", "ka": "ახალი ზელანდია" }, "code": "NZ" },
            { "text": { "en": "Nicaragua", "ka": "ნიკარაგუა" }, "code": "NI" },
            { "text": { "en": "Niger", "ka": "ნიგერი" }, "code": "NE" },
            { "text": { "en": "Nigeria", "ka": "ნიგერია" }, "code": "NG" },
            { "text": { "en": "Niue", "ka": "ნიუე" }, "code": "NU" },
            { "text": { "en": "Norfolk Island", "ka": "ნორფოლკის კუნძულები" }, "code": "NF" },
            { "text": { "en": "Northern Mariana Islands", "ka": "ჩრდილოეთ მარიანას კუნძულები" }, "code": "MP" },
            { "text": { "en": "Norway", "ka": "ნორვეგია" }, "code": "NO" },
            { "text": { "en": "Oman", "ka": "ომანი" }, "code": "OM" },
            { "text": { "en": "Pakistan", "ka": "პაკისტანი" }, "code": "PK" },
            { "text": { "en": "Palau", "ka": "პალაუ" }, "code": "PW" },
            { "text": { "en": "Palestinian Territory, Occupied", "ka": "პალესტინა" }, "code": "PS" },
            { "text": { "en": "Panama", "ka": "პანამა" }, "code": "PA" },
            { "text": { "en": "Papua New Guinea", "ka": "პაპუა ახალი გვინეა" }, "code": "PG" },
            { "text": { "en": "Paraguay", "ka": "პარაგვაი" }, "code": "PY" },
            { "text": { "en": "Peru", "ka": "პერუ" }, "code": "PE" },
            { "text": { "en": "Philippines", "ka": "ფილიპინები" }, "code": "PH" },
            { "text": { "en": "Pitcairn", "ka": "პიტკერნის კუნძულები" }, "code": "PN" },
            { "text": { "en": "Poland", "ka": "პოლონეთი" }, "code": "PL" },
            { "text": { "en": "Portugal", "ka": "პორტუგალია" }, "code": "PT" },
            { "text": { "en": "Puerto Rico", "ka": "პუერტო რიკო"}, "code": "PR" },
            { "text": { "en": "Qatar", "ka": "ყატარი" }, "code": "QA" },
            { "text": { "en": "Reunion", "ka": "რეიუნიონი" }, "code": "RE" },
            { "text": { "en": "Romania", "ka": "რუმინეთი" }, "code": "RO" },
            { "text": { "en": "Russian Federation", "ka": "რუსეთი" }, "code": "RU" },
            { "text": { "en": "RWANDA", "ka": "რუანდა" }, "code": "RW" },
            { "text": { "en": "Saint Helena", "ka": "წმ. ელენე" }, "code": "SH" },
            { "text": { "en": "Saint Kitts and Nevis", "ka": "სენტ-კიტსი და ნევისი" }, "code": "KN" },
            { "text": { "en": "Saint Lucia", "ka": "სენტ-ლუსია" }, "code": "LC" },
            { "text": { "en": "Saint Pierre and Miquelon", "ka": "სენ-პიერი და მიკელონი" }, "code": "PM" },
            { "text": { "en": "Saint Vincent and the Grenadines", "ka": "სენტ-ვინსენტი და გრენადინები" }, "code": "VC" },
            { "text": { "en": "Samoa", "ka": "სამოა" }, "code": "WS" },
            { "text": { "en": "San Marino", "ka": "სან-მარინო" }, "code": "SM" },
            { "text": { "en": "Sao Tome and Principe", "ka": "სან-ტომე და პრინსიპი" }, "code": "ST" },
            { "text": { "en": "Saudi Arabia", "ka": "საუდის არაბეთი" }, "code": "SA" },
            { "text": { "en": "Senegal", "ka": "სენეგალი" }, "code": "SN" },
            { "text": { "en": "Serbia and Montenegro", "ka": "სერბეთI და მონტენეგრო" }, "code": "CS" },
            { "text": { "en": "Seychelles", "ka": "სეიშელის კუნძულები" }, "code": "SC" },
            { "text": { "en": "Sierra Leone", "ka": "სიერა ლეონე" }, "code": "SL" },
            { "text": { "en": "Singapore", "ka": "სინგაპური" }, "code": "SG" },
            { "text": { "en": "Slovakia", "ka": "სლოვაკეთი" }, "code": "SK" },
            { "text": { "en": "Slovenia", "ka": "სლოვენია" }, "code": "SI" },
            { "text": { "en": "Solomon Islands", "ka": "სოლომონის კუნძულები" }, "code": "SB" },
            { "text": { "en": "Somalia", "ka": "სომალი" }, "code": "SO" },
            { "text": { "en": "South Africa", "ka": "სამხრეთ აფრიკის რესპუბლიკა" }, "code": "ZA" },
            { "text": { "en": "South Georgia and the South Sandwich Islands", "ka": "სამხრეთი გეორგია და სამხრეთ სენდვიჩის კუნძულები" }, "code": "GS" },
            { "text": { "en": "Spain", "ka": "ესპანეთი" }, "code": "ES" },
            { "text": { "en": "Sri Lanka", "ka": "შრილანკა" }, "code": "LK" },
            { "text": { "en": "Sudan", "ka": "სუდანი" }, "code": "SD" },
            { "text": { "en": "Suriname", "ka": "სურინამი" }, "code": "SR" },
            { "text": { "en": "Svalbard and Jan Mayen", "ka": "შპიცბერგენი და იან-მაიენი" }, "code": "SJ" },
            { "text": { "en": "Swaziland", "ka": "ესვატინი" }, "code": "SZ" },
            { "text": { "en": "Sweden", "ka": "შვედეთი" }, "code": "SE" },
            { "text": { "en": "Switzerland", "ka": "შვეიცარია" }, "code": "CH" },
            { "text": { "en": "Syrian Arab Republic", "ka": "სირიის არაბთა რესპუბლიკა" }, "code": "SY" },
            { "text": { "en": "Taiwan, Province of China", "ka": "ტაივანი" }, "code": "TW" },
            { "text": { "en": "Tajikistan", "ka": "ტაჯიკეთი" }, "code": "TJ" },
            { "text": { "en": "Tanzania, United Republic of", "ka": "ტანზანიის გაერთიანებული რესპუბლიკა" }, "code": "TZ" },
            { "text": { "en": "Thailand", "ka": "ტაილანდი" }, "code": "TH" },
            { "text": { "en": "Timor-Leste", "ka": "აღმოსავლეთი ტიმორი" }, "code": "TL" },
            { "text": { "en": "Togo", "ka": "ტოგო" }, "code": "TG" },
            { "text": { "en": "Tokelau", "ka": "ტოკელაუ" }, "code": "TK" },
            { "text": { "en": "Tonga", "ka": "ტონგა" }, "code": "TO" },
            { "text": { "en": "Trinidad and Tobago", "ka": "ტრინიდადი და ტობაგო" }, "code": "TT" },
            { "text": { "en": "Tunisia", "ka": "ტუნისი" }, "code": "TN" },
            { "text": { "en": "Turkey", "ka": "თურქეთი" }, "code": "TR" },
            { "text": { "en": "Turkmenistan", "ka": "თურქმენეთი" }, "code": "TM" },
            { "text": { "en": "Turks and Caicos Islands", "ka": "ტერქსისა და კაიკოსის კუნძულები" }, "code": "TC" },
            { "text": { "en": "Tuvalu", "ka": "ტუვალუ" }, "code": "TV" },
            { "text": { "en": "Uganda", "ka": "უგანდა" }, "code": "UG" },
            { "text": { "en": "Ukraine", "ka": "უკრაინა" }, "code": "UA" },
            { "text": { "en": "United Arab Emirates", "ka": "არაბეთის გაერთიანებული საემიროები" }, "code": "AE" },
            { "text": { "en": "United Kingdom", "ka": "გაერთიანებული სამეფო" }, "code": "GB" },
            { "text": { "en": "United States", "ka": "ამერიკის შეერთებული შტატები" }, "code": "US" },
            { "text": { "en": "United States Minor Outlying Islands", "ka": "აშშ-ის მიმდებარე მცირე კუნძულები" }, "code": "UM" },
            { "text": { "en": "Uruguay", "ka": "ურუგვაი" }, "code": "UY" },
            { "text": { "en": "Uzbekistan", "ka": "უზბეკეთი" }, "code": "UZ" },
            { "text": { "en": "Vanuatu", "ka": "ვანუატუ" }, "code": "VU" },
            { "text": { "en": "Venezuela", "ka": "ვენესუელა" }, "code": "VE" },
            { "text": { "en": "Viet Nam", "ka": "ვიეტნამი" }, "code": "VN" },
            { "text": { "en": "Virgin Islands, British", "ka": "ბრიტანეთის ვირჯინიის კუნძულები" }, "code": "VG" },
            { "text": { "en": "Virgin Islands, U.S.", "ka": "აშშ-ის ვირჯინიის კუნძულები" }, "code": "VI" },
            { "text": { "en": "Wallis and Futuna", "ka": "უოლისი და ფუტუნა" }, "code": "WF" },
            { "text": { "en": "Western Sahara", "ka": "საჰარის არაბთა დემოკრატიული რესპუბლიკა" }, "code": "EH" },
            { "text": { "en": "Yemen", "ka": "იემენი" }, "code": "YE" },
            { "text": { "en": "Zambia", "ka": "ზამბია" }, "code": "ZM" },
            { "text": { "en": "Zimbabwe", "ka": "ზიმბაბვე" }, "code": "ZW" }
        ];

        return list;
    },

    forLanguage(language) {
        var list = this.getList();
        var newList = [];
        list.forEach(Obj => {
            newList.push({
                "text": Obj['text'][language],
                "code": Obj['code']
            });
        });
        return newList;
    }
}

export default countries;