<template>
  <v-dialog v-model="dialogState" persistent max-width="800" v-if="transactionData">
    <v-card>
      <v-toolbar color="accent" fixed>
        <v-toolbar-title>
          <v-icon left>mdi-pencil</v-icon>
          {{$vuetify.lang.t('$vuetify.action.edit_transaction')}}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-title class="headline"></v-card-title>
      <v-card-text style="min-height:430px;">
        <v-form ref="contactEditForm">
          <v-row align="start">
            <v-col md="6">
              <v-text-field
                v-model="transactionData.amount"
                :label="$vuetify.lang.t('$vuetify.tables.amount')"
                type="number"
                :error="validator.amount.error_show"
                :error-messages="validator.amount.error_msg"
                @keyup="validateForm"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" v-if="transactionData.payment_type_selected.key == 'barcode'">
              <v-text-field
                v-model="transactionData.barcode_count"
                :label="$vuetify.lang.t('$vuetify.company.barcode_count')"
                type="number"
                :error="validator.barcode_count.error_show"
                :error-messages="validator.barcode_count.error_msg"
                @keyup="validateForm"
              ></v-text-field>
            </v-col>
            <v-col md="6" v-if="transactionData.payment_type_selected.key == 'membership'">
              <v-text-field
                v-model="transactionData.next_payment_amount"
                :label="$vuetify.lang.t('$vuetify.tables.next_payment_amount')+' *'"
                type="number"
                :error="validator.next_payment_amount.error_show"
                :error-messages="validator.next_payment_amount.error_msg"
                @keyup="validateForm"
              ></v-text-field>
            </v-col>
            <v-col md="6" v-if="transactionData.payment_type_selected.key == 'membership'">
              <div class="v-input v-input--hide-details theme--light v-text-field">
                <div class="v-input__control">
                  <div class="v-input__slot" style="width:100" append-icon="mdi-magnify">
                    <div class="v-text-field__slot">
                      <datepicker
                        v-model="transactionData.payed_from"
                        :language="dateLanguage()"
                        :monday-first="true"
                        :placeholder="$vuetify.lang.t('$vuetify.company.payed_from')+' *'"
                        :error="validator.payed_from.error_show"
                        :error-messages="validator.payed_from.error_msg"
                        :highlighted="currentDate"
                      ></datepicker>
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col md="6" v-if="transactionData.payment_type_selected.key == 'membership'">
              <div class="v-input v-input--hide-details theme--light v-text-field">
                <div class="v-input__control">
                  <div class="v-input__slot" style="width:100" append-icon="mdi-magnify">
                    <div class="v-text-field__slot">
                      <datepicker
                        v-model="transactionData.payed_to"
                        :language="dateLanguage()"
                        :monday-first="true"
                        :placeholder="$vuetify.lang.t('$vuetify.company.payed_to')+' *'"
                        :error="validator.payed_to.error_show"
                        :error-messages="validator.payed_to.error_msg"
                        :highlighted="currentDate"
                      ></datepicker>
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols="12">
              <v-select
                :label="$vuetify.lang.t('$vuetify.manager.status')+ ' *'"
                :items="statuses"
                v-model="transactionData.status_key"
                item-text="value"
                item-value="key"
                class="mt-5"
                @change="validateForm"
                @select="validateForm"
              >
              </v-select>
            </v-col>
            <v-col cols="12">
              <v-textarea
                :label="$vuetify.lang.t('$vuetify.company.comment')"
                :value="transactionData.note"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn @click="closePopup">
          <v-icon class="mr-3">mdi-close</v-icon>
          {{$vuetify.lang.t('$vuetify.action.cancel')}}
        </v-btn>

        <v-btn @click="updateTransaction" :disabled="validator.hasError">
          <v-icon class="mr-3">mdi-content-save</v-icon>
          {{$vuetify.lang.t('$vuetify.action.update')}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Api from "@/services/api";
import TransactionHelper from "@/helpers/transaction";
import Datepicker from "vuejs-datepicker";
import { en, ge } from "vuejs-datepicker/dist/locale";

export default {
  components: {
    Datepicker
  },
  data: function() {
    return {
      currentDate:{ dates: [new Date(new Date().toISOString().slice(0, 10))]},
      dialogState: false,
      transactionData: false,
      transactionDataOriginal: false,
      validator: TransactionHelper.validatorModel(),
      statuses: []
    };
  },
  computed: {
    transactionTypes() {
      return TransactionHelper.itemTypes();
    }
  },
  watch: {
    popupEditTransaction: function(newVal) {
      this.dialogState = newVal;
    },
    transaction: function(newVal) {
      this.statuses = this.$i18n["messages"][this.$i18n.locale]["$vuetify"]["tr_statuses"];
      this.transactionData = Object.assign({}, newVal);
      this.transactionDataOriginal = Object.assign({}, newVal);
      this.validateForm();
    },
    "transactionData.payed_from": function() {
      this.validator = TransactionHelper.validateForm(this.transactionData);
    },
    "transactionData.payed_to": function() {
      this.validator = TransactionHelper.validateForm(this.transactionData);
    }
  },
  methods: {
    dateLanguage() {
      var dateLocales = new Array();
      dateLocales["en"] = en;
      dateLocales["ka"] = ge;
      return dateLocales[this.$i18n.locale];
    },
    validateForm() {
      var val = this.transactionData;
      val["payment_type"] = val["payment_type_selected"]["key"];
      this.validator = TransactionHelper.validateForm(val);
    },
    closePopup() {
      this.dialogState = false;
      this.$emit("closeEditTransactionPopup");
      this.validator = TransactionHelper.validatorModel();
      this.transactionData = Object.assign({}, this.transactionDataOriginal);
    },
    updateTransaction() {
      const d = {
        id: this.transactionData.id,
        company_id: this.transactionData.company.id,
        payment_type: this.transactionData.payment_type.key,
        currency: "gel",
        amount: this.transactionData.amount,
        payed_from: this.transactionData.payed_from,
        payed_to: this.transactionData.payed_to,
        next_payment_amount: this.transactionData.next_payment_amount,
        barcode_count: this.transactionData.barcode_count,
        status: this.transactionData.status_key,
        note: this.transactionData.note
      };

      Api.payment("update", d).then(() => {
        this.$emit("reload");
        this.$toast.ok(this.$vuetify.lang.t("$vuetify.action.update_success"));
        this.closePopup();
      });
    }
  },
  props: ["transaction", "popupEditTransaction"]
};
</script>
<style scoped>
.vdp-datepicker {
  width: 100%;
}
</style>