export const validator = {
    hasError: true,
    target_market: {
        error_show: false,
        error_msg: null
    },
    brand: {
        ka: {
            error_show: false,
            error_msg: null
        },
        en: {
            error_show: false,
            error_msg: null
        }
    },
    barcode_type: {
        error_show: false,
        error_msg: null
    },
    barcode_packaging_level: {
        error_show: false,
        error_msg: null
    },
    barcode_parent: {
        error_show: false,
        error_msg: null
    },
    name: {
        ka: {
            error_show: false,
            error_msg: null
        },
        en: {
            error_show: false,
            error_msg: null
        }
    },
    type: {
        ka: {
            error_show: false,
            error_msg: null
        },
        en: {
            error_show: false,
            error_msg: null
        }
    },
    volume_amount: {
        error_show: false,
        error_msg: null
    },
    volume_unit: {
        error_show: false,
        error_msg: null
    },
    package: {
        ka: {
            error_show: false,
            error_msg: null
        },
        en: {
            error_show: false,
            error_msg: null
        }
    },
    barcode_root: {
        error_show: false,
        error_msg: null
    },
    gtin_segment_code: {
        error_show: false,
        error_msg: null
    }
}

export const newItem = {
    img: null,
    target_market: null,
    brand: {
        ka: null,
        en: null
    },
    name: {
        ka: null,
        en: null
    },
    type: {
        ka: null,
        en: null
    },
    package: {
        ka: null,
        en: null
    },
    barcode_type: null,
    barcode_packaging_level: null,
    barcode_parent: null,
    volume_amount: null,
    volume_unit: null,
    barcode_root: null,
    gtin_segment_code: null
}