<template>
  <div
    class="base-image-input placeholderBG"
    :style="{ 'background-image': `url(${imageData})` } "
    @click="chooseImage"
  >
    <span class="placeholder">{{$vuetify.lang.t('$vuetify.action.change_image')}}</span>
    <input class="file-input" ref="fileInput" id="fileInput" type="file" @input="onSelectFile" v-if="!limited"/>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageData: this.defaultImage
    };
  },
  methods: {
    chooseImage() {
      this.$refs.fileInput.click();
    },
    onSelectFile() {
      const input = this.$refs.fileInput;
      const files = input.files;
      if (files && files[0]) {
        const reader = new FileReader();
        reader.onload = e => {
          this.imageData = e.target.result;
          this.$emit("fileToUplaod", e.target.result);
        };
        reader.readAsDataURL(files[0]);
      }
    }
  },
  watch: {
    defaultImage(newVal) {
      this.imageData = newVal;
    }
  },
  props: ["defaultImage","limited"]
};
</script>

<style scoped>
.base-image-input {
  display: block;
  width:20vw;
  min-width: 250px;
  height: 200px;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}
.placeholder {
  width: 100%;
  height: 230px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}
.placeholderBG {
  background-color: #dadada;
  border: 1px solid #c1c1c1
}
.file-input {
  display: none;
}
</style>