<template>
  <v-dialog v-model="dialogState" persistent max-width="600px">
    <v-card>
      <v-toolbar color="accent" fixed>
        <v-toolbar-title>
          <v-icon left class="mt-n1">mdi-delete</v-icon>
          {{ $vuetify.lang.t("$vuetify.manager.delete_product") }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pt-3">
        <h3>{{$vuetify.lang.t('$vuetify.action.delete_confirm')}}</h3>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="closePopup">
          <v-icon class="mr-3">mdi-close</v-icon>
          {{ $vuetify.lang.t("$vuetify.action.cancel") }}
        </v-btn>
        <v-btn @click="removeProduct">
          <v-icon class="mr-3">mdi-delete</v-icon>
          {{ $vuetify.lang.t("$vuetify.action.delete") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Api from "@/services/api";
export default {
  data: function() {
    return {
      dialogState: false,
    };
  },
  methods: {
    closePopup() {
      this.dialogState = false;
      this.$emit("closeRemoveProductPopup");
    },
    removeProduct() {
      Api.product('delete', this.dpdata.id).then(data => {
        this.$emit('closeRemoveProductPopup')
        this.$emit('reload', data)
      });
    }
  },
  watch: {
    removeProductPopup(newVal) {
      this.dialogState = newVal;
    }
  },
  props: ["removeProductPopup", "dpdata"],
};
</script>
