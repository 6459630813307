import vm from '@/main.js'
import * as HelperModel from '@/models/transaction.js'

class TransactionHelpers {
    itemTypes() {
        const types = vm.$i18n.messages[vm.$i18n.locale]["$vuetify"][
            "transaction_types"
        ];
        var res = [];
        for (var t in types) {
            res.push({
                name: types[t],
                key: t
            });
        }
        return res
    }

    newItemModel() {
        return JSON.parse(JSON.stringify(HelperModel.newItem))
    }

    validatorModel() {
        return JSON.parse(JSON.stringify(HelperModel.validator))
    }

    validateForm(filled) {
        var validator = this.validatorModel()
        if (filled.amount <= 0) {
            validator.amount.error_show = true;
            validator.amount.error_msg = vm.$vuetify.lang.t(
                "$vuetify.errors.invalid_amount"
            );
            validator.hasError = true;
        } else {
            validator.amount.error_show = false;
            validator.amount.error_msg = null;
            validator.hasError = false;
        }

        if (filled.payment_type === null) {
            validator.payment_type.error_show = true;
            validator.payment_type.error_msg = vm.$vuetify.lang.t(
                "$vuetify.errors.invalid_transaction_type"
            );
            validator.hasError = true;
        } else {
            validator.payment_type.error_show = false;
            validator.payment_type.error_msg = null;
            validator.hasError = validator.hasError ? true : false;
        }

        if (filled.payment_type == 'membership' && filled.payed_from === null) {
            validator.payed_from.error_show = true;
            validator.payed_from.error_msg = vm.$vuetify.lang.t(
                "$vuetify.errors.date_required"
            );
            validator.hasError = true;
        } else {
            validator.payed_from.error_show = false;
            validator.payed_from.error_msg = null;
            validator.hasError = validator.hasError ? true : false;
        }

        if (filled.payment_type == 'membership' && filled.payed_to === null) {
            validator.payed_to.error_show = true;
            validator.payed_to.error_msg = vm.$vuetify.lang.t(
                "$vuetify.errors.date_required"
            );
            validator.hasError = true;
        } else {
            validator.payed_to.error_show = false;
            validator.payed_to.error_msg = null;
            validator.hasError = validator.hasError ? true : false;
        }

        if (filled.payment_type == 'barcode' && filled.barcode_count <= 0) {
            validator.barcode_count.error_show = true;
            validator.barcode_count.error_msg = vm.$vuetify.lang.t(
                "$vuetify.errors.invalid_transaction_type"
            );
            validator.hasError = true;
        } else {
            validator.barcode_count.error_show = false;
            validator.barcode_count.error_msg = null;
            validator.hasError = validator.hasError ? true : false;
        }

        return validator;
    }
}

const TransactionHelper = new TransactionHelpers
export default TransactionHelper