<template>
  <div>
    <v-row>
      <v-col cols="12">
        <Table
          :servicePath="'payment/list'"
          :headers="tableHeaders"
          :title="tableTitle"
          :itemsPerPageSent="itemsPerPageSent"
          :sortByKey="sortBy"
          :sortDesc="sortDesc"
          tableType="payments"
          icon="mdi-cash-multiple"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Table from "@/components/shared/Table";
export default {
  components: {
    Table
  },
  data: function() {
    return {
      sortBy: ["date"],
      sortDesc: [true],
      itemsPerPageSent: 50,
      fieldClickable: {
        field: "org_name",
        to: "/manager/company/list",
        key: "company_id"
      }
    };
  },
  computed: {
    tableTitle() {
      return this.$vuetify.lang.t("$vuetify.cards.incomes");
    },
    tableHeaders() {
      var path = this.$route.path.replace('/manager/finances/','')
      path = path.replace( /[0-9]/g, '')
      var p = path.replace('/','')
      var b = p == 'barcode' ? '' : ' d-none';
      var m = p == 'membership' ? '' : ' d-none';
      return [
        {
          text: this.$vuetify.lang.t("$vuetify.tables.transactio_id"),
          value: "id"
        },
        {
          text: this.$vuetify.lang.t("$vuetify.tables.reg_id"),
          value: "company_id",
          align: " d-none"
        },
        {
          text: this.$vuetify.lang.t("$vuetify.tables.company"),
          value: "company.name"
        },
        {
          text: this.$vuetify.lang.t("$vuetify.tables.amount"),
          value: "amount"
        },
        {
          text: this.$vuetify.lang.t("$vuetify.tables.type"),
          value: "payment_type"
        },
        {
          text: this.$vuetify.lang.t("$vuetify.company.barcode_count"),
          value: "barcode_count",
          align: b
        },
        {
          text: this.$vuetify.lang.t("$vuetify.manager.status"),
          value: "status"
        },
        {
          text: this.$vuetify.lang.t("$vuetify.company.payed_from"),
          value: "payed_from",
          align: m
        },
        {
          text: this.$vuetify.lang.t("$vuetify.company.payed_to"),
          value: "payed_to",
          align: m
        },
        {
          text: this.$vuetify.lang.t("$vuetify.company.payment_date"),
          value: "insert_date"
        },
        {
          text: this.$vuetify.lang.t("$vuetify.action.edit"),
          value: "editTransaction",
          sortable: false
        }
      ];
    }
  }
};
</script>