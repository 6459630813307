<template>
  <v-dialog v-model="dialogState" persistent max-width="1200px">
    <v-card>
      <v-toolbar color="accent" fixed>
        <v-toolbar-title>
          <v-icon left>mdi-plus</v-icon>
          {{$vuetify.lang.t('$vuetify.action.add_company')}}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form>
          <v-row align="start">
            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.company.name_ka')+ ' *'"
                v-model="newItem.name.ka"
                :error="validator.name_ka.error_show"
                :error-messages="validator.name_ka.error_msg"
                @keyup="validateForm"
              ></v-text-field>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.company.name_en')+ ' *'"
                v-model="newItem.name.en"
                :error="validator.name_en.error_show"
                :error-messages="validator.name_en.error_msg"
                @keyup="validateForm"
                v-on:keypress="latinOnly($event)"
              ></v-text-field>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-text-field
                id="identity_code"
                v-model="newItem.id_code"
                :label="$vuetify.lang.t('$vuetify.company.identity_code')+' *'"
                type="number"
                :error="validator.id_code.error_show"
                :error-messages="validator.id_code.error_msg"
                @keyup="validateForm"
              ></v-text-field>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-text-field
                id="prefix"
                v-model="newItem.prefix"
                :label="$vuetify.lang.t('$vuetify.company.prefix')"
                type="number"
                :error="validator.prefix.error_show"
                :error-messages="validator.prefix.error_msg"
                @keyup="validateForm"
              ></v-text-field>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-select
                v-model="newItem.status"
                :items="companyStatuses"
                :label="$vuetify.lang.t('$vuetify.manager.status')"
                item-text="text"
                item-value="code"
              >
              </v-select>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-select
                v-model="newItem.city"
                :items="cities"
                :label="$vuetify.lang.t('$vuetify.company.city')+' *'"
                item-text="name"
                item-value="key"
                :error="validator.city.error_show"
                :error-messages="validator.city.error_msg"
              >
                <template slot="selection" slot-scope="citiesKA">{{ citiesKA.item.name }}</template>
                <template slot="item" slot-scope="citiesKA">{{ citiesKA.item.name }}</template>
              </v-select>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.company.address_ka')+ ' *'"
                v-model="newItem.address.ka"
                :error="validator.address_ka.error_show"
                :error-messages="validator.address_ka.error_msg"
                @keyup="validateForm"
              ></v-text-field>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.company.address_en')+ ' *'"
                v-model="newItem.address.en"
                :error="validator.address_en.error_show"
                :error-messages="validator.address_en.error_msg"
                @keyup="validateForm"
                  v-on:keypress="latinOnly($event)"
              ></v-text-field>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-select
                v-model="newItem.main_production_field"
                id="production_main_field_ka"
                :items="mainFields"
                :menu-props="{ top: true, offsetY: true }"
                :label="$vuetify.lang.t('$vuetify.company.production_main_field')+' *'"
                :error="validator.main_production_field.error_show"
                :error-messages="validator.main_production_field.error_msg"
                item-text="name"
                item-value="key"
              ></v-select>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-select
                id="production_field_ka"
                v-model="newItem.production_fields"
                :items="fields"
                :menu-props="{ top: true, offsetY: true }"
                :label="$vuetify.lang.t('$vuetify.company.production_fields')"
                item-text="name"
                item-value="key"
                attach
                chips
                multiple
                deletable-chips
              ></v-select>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-text-field
                id="manager_id"
                v-model="newItem.manager_id"
                :label="$vuetify.lang.t('$vuetify.company.manager_id')+' *'"
                type="number"
                :error="validator.manager_id.error_show"
                :error-messages="validator.manager_id.error_msg"
                @keyup="validateForm"
              ></v-text-field>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-select
                v-model="newItem.yearly_turnover"
                :items="turnovers"
                :label="$vuetify.lang.t('$vuetify.company.yearly_turnover')"
                item-text="name"
                item-value="key"
              >
                <template slot="selection" slot-scope="turnovers">{{ turnovers.item.name }}</template>
                <template slot="item" slot-scope="turnovers">{{ turnovers.item.name }}</template>
              </v-select>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-text-field
                id="postal_code"
                v-model="newItem.postal_code"
                :label="$vuetify.lang.t('$vuetify.company.postal_code')"
              ></v-text-field>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.company.email')+ ' *'"
                v-model="newItem.email"
                :error="validator.email.error_show"
                :error-messages="validator.email.error_msg"
                @keyup="validateForm"
              ></v-text-field>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.company.phone')"
                v-model="newItem.phone"
                @keyup="validateForm"
              ></v-text-field>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.company.website')"
                v-model="newItem.website"
                @keyup="validateForm"
              ></v-text-field>
            </div>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="closePopup">
          <v-icon class="mr-3">mdi-close</v-icon>
          {{$vuetify.lang.t('$vuetify.action.cancel')}}
        </v-btn>
        <v-btn @click="addCompany" :disabled="validator.hasError">
          <v-icon class="mr-3">mdi-content-save</v-icon>
          {{$vuetify.lang.t('$vuetify.action.save')}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Api from "@/services/api";
import CompanyHelper from "@/helpers/company";
import turnoverRanges from "@/modules/turnovers";
export default {
  data: function() {
    return {
      dialogState: false,
      newItem: CompanyHelper.newItemModel(),
      validator: CompanyHelper.validatorModel()
    };
  },
  methods: {
    validateForm() {
      if (this.newItem.id_code < 0) {
        this.newItem.id_code = null;
      }
      this.validator = CompanyHelper.validateForm(this.newItem);
    },
    closePopup() {
      this.dialogState = false;
      this.$emit("closeAddCompanyPopup");
      this.validator = CompanyHelper.validatorModel();
      this.newItem = CompanyHelper.newItemModel();
    },
    addCompany() {
      if(this.newItem.prefix == null || this.newItem.prefix.length < 2) {
        delete this.newItem.prefix;
      }

      Api.company("create", this.newItem).then(data => {
        this.$router.push({
          path: "/manager/company/" + data.data.id
        });
        this.closePopup();
        this.$toast.ok(this.$vuetify.lang.t("$vuetify.action.update_success"));
      });
    }
  },
  computed: {
    turnovers() {
      return turnoverRanges(
        this.$vuetify.lang.t("$vuetify.company.mln"),
        this.$vuetify.lang.t("$vuetify.company.mln_and_more")
      );
    }
  },
  watch: {
    addCompanyPopup: function(newVal) {
      this.dialogState = newVal;
    }
  },
  props: ["addCompanyPopup"],
  beforeCreate() {
    this.companyStatuses = this.$i18n.messages[this.$i18n.locale]["$vuetify"]["company"]['statuses'];
    this.cities = [];
    let locCitiesArray = this.$i18n.messages[this.$i18n.locale].$vuetify.city;
    for (var lkey in locCitiesArray) {
      this.cities.push({ name: locCitiesArray[lkey], key: lkey });
    }

    this.mainFields = [];
    this.fields = [];
    let locFieldsArray = this.$i18n.messages[this.$i18n.locale].$vuetify
      .production_fields;
    for (var fkey in locFieldsArray) {
      this.fields.push({
        name: locFieldsArray[fkey],
        key: fkey
      });
      this.mainFields.push({
        name: locFieldsArray[fkey],
        key: fkey
      });
    }
  }
};
</script>