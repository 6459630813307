<template>
  <v-dialog v-model="dialogState" persistent max-width="600">
    <v-card>
      <v-card-text class="pt-3">
        <h3 v-if="!download">{{ $vuetify.lang.t("$vuetify.action.download_prepare") }}</h3>
        <h3 v-if="download">{{ $vuetify.lang.t("$vuetify.action.download_ready") }}</h3>
      </v-card-text>
      <v-card-actions>
        <v-spacer ></v-spacer>
        <v-btn v-if="download" @click="close">{{$vuetify.lang.t("$vuetify.close")}}</v-btn>
        <v-btn :loading="loading" :href="download" @click="close">{{$vuetify.lang.t("$vuetify.action.download")}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data: function() {
    return {
        dialogState: false,
        download: false,
        loading: true,
    };
  },
  methods: {
      close() {
        this.loading = true;
        this.download = false;
        this.$emit('close-download')
      }
  },
  watch: {
    popupDownload: function(newVal) {
        this.dialogState = newVal;
    },
    urlDownload: function(newVal) {
        if(newVal) {
            this.loading = false;
            this.download = newVal;
        }
    }
  },
  props: ["popupDownload", "urlDownload"],
};
</script>
