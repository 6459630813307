const turnoverRanges = function (mln, mln_more) {
    return [
        {
            name: "1 - 10 000",
            key: 10000
        },
        {
            name: "10 001 - 50 000",
            key: 50000
        },
        {
            name: "50 001 - 100 000",
            key: 100000
        },
        {
            name: "100 001 - 250 000",
            key: 250000
        },
        {
            name: "250 001 - 500 000",
            key: 500000
        },
        {
            name:
                "500 001 - 1 " + mln,
            key: 1000000
        },
        {
            name: "1 - 2 " + mln,
            key: 2000000
        },
        {
            name: "2 - 3 " + mln,
            key: 3000000
        },
        {
            name: "3 - 5 " + mln,
            key: 5000000
        },
        {
            name: "5 - 10 " + mln,
            key: 10000000
        },
        {
            name: "10 - 20 " + mln,
            key: 20000000
        },
        {
            name: "20 - 30 " + mln,
            key: 30000000
        },
        {
            name: "30 - 50 " + mln,
            key: 50000000
        },
        {
            name: "50 - 100 " + mln,
            key: 100000000
        },
        {
            name:
                "100 - 500 " + mln,
            key: 500000000
        },
        {
            name: "500 " + mln_more,
            key: 500000001
        }
    ];
}

export default turnoverRanges