<template>
  <v-dialog v-model="dialogState" persistent max-width="100%">
    <v-card>
      <v-toolbar color="accent">
        <v-toolbar-title style="width:100%">
          <v-container fluid>
            <v-row>
              <v-col>
                <v-icon class="iconFix mr-2">mdi-email</v-icon>
                <span>{{
                  $vuetify.lang.t("$vuetify.navigations.mass_send")
                }}</span>
              </v-col>
            </v-row>
          </v-container>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form>
          <v-container fluid>
            <v-row>
              <v-col md="6">
                {{ $vuetify.lang.t("$vuetify.message.channels") }}
                <v-row class="ma-0 pa-0 pt-1">
                  <v-col class="ma-0 pa-0">
                    <v-checkbox
                      class="mt-1"
                      v-model="message.channel.inbox"
                      label="Inbox"
                      @change="validate"
                    ></v-checkbox>
                  </v-col>
                  <v-col class="ma-0 pa-0">
                    <v-checkbox
                      class="mt-1 mb-0"
                      v-model="message.channel.sms"
                      label="SMS"
                      @change="validate"
                    ></v-checkbox>
                  </v-col>
                  <v-col class="ma-0 pa-0">
                    <v-checkbox
                      class="mt-1"
                      v-model="message.channel.email"
                      label="Email"
                      @change="validate"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-if="message.channel.inbox || message.channel.email"
                  v-model="message.title.ka"
                  :label="$vuetify.lang.t('$vuetify.message.subject_ka')"
                  @keyup.delete="validate"
                  @input="validate"
                  @paste="validate"
                ></v-text-field>
                <vue-editor
                  v-model="message.body.ka"
                  :label="$vuetify.lang.t('$vuetify.message.message_ka')"
                  @keyup.delete="validate"
                  @input="validate"
                  @paste="validate"
                  @update="validate"
                  :editorToolbar="customToolbar"
                ></vue-editor>
                <v-file-input
                  @change="fileUpload('ka')"
                  :placeholder="$vuetify.lang.t('$vuetify.message.file_ka')"
                  show-size
                  truncate-length="50"
                  ref="file_ka"
                  v-if="message.channel.email"
                  :clearable="false"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-if="message.channel.inbox || message.channel.email"
                  v-model="message.title.en"
                  :label="$vuetify.lang.t('$vuetify.message.subject_en')"
                  v-on:keypress="latinOnly($event)"
                  @keyup.delete="validate"
                  @input="validate"
                  @paste="validate"
                ></v-text-field>
                <vue-editor
                  v-model="message.body.en"
                  :label="$vuetify.lang.t('$vuetify.message.message_en')"
                  v-on:keypress="
                    validate();
                    latinOnly($event);
                  "
                  @keyup.delete="validate"
                  @input="validate"
                  @paste="validate"
                  :editorToolbar="customToolbar"
                ></vue-editor>
                <v-file-input
                  @change="fileUpload('en')"
                  :placeholder="$vuetify.lang.t('$vuetify.message.file_en')"
                  show-size
                  truncate-length="50"
                  ref="file_en"
                  v-if="message.channel.email"
                  :clearable="false"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="closePopup">
          <v-icon class="mr-3">mdi-close</v-icon>
          {{ $vuetify.lang.t("$vuetify.action.cancel") }}
        </v-btn>
        <v-btn @click="spam()" :disabled="disableSend" :loading="loading">
          <v-icon class="mr-3">mdi-send</v-icon>
          {{ $vuetify.lang.t("$vuetify.action.send_message") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Api from "@/services/api";
import { VueEditor } from "vue2-editor";
export default {
  props: ["popupNotification", "filters"],
  components: {
    VueEditor,
  },
  data: function() {
    return {
      dialogState: false,
      disableSend: true,
      loading: false,
      organizations: [],
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        ["blockquote"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ color: [] }, { background: [] }],
        ["link"]
      ],
      messageModel: {
        channel: {
          inbox: false,
          sms: false,
          email: false,
        },
        title: {
          ka: null,
          en: null,
        },
        body: {
          ka: null,
          en: null,
        },
        file: {
          ka: false,
          en: false,
        },
      },
      message: {
        channel: {
          inbox: false,
          sms: false,
          email: false,
        },
        title: {
          ka: null,
          en: null,
        },
        body: {
          ka: null,
          en: null,
        },
        file: {
          ka: false,
          en: false,
        },
      },
    };
  },
  computed: {},
  methods: {
    fileUpload(locale) {
      this.loading = true;
      let f = this.$refs["file_" + locale].$refs.input.files[0];
      let formData = new FormData();
      formData.append("file", f);
      Api.media("upload", formData).then(({ data }) => {
        this.message.file[locale] = data.id;
        this.$toast.ok(this.$vuetify.lang.t("$vuetify.manager.imported"));
        this.loading = false;
      });
    },
    closePopup() {
      this.dialogState = false;
      this.message = JSON.parse(JSON.stringify(this.messageModel));
      this.$emit("close-notification");
    },
    validate() {
      if (
        !this.message.channel.inbox &&
        !this.message.channel.email &&
        !this.message.channel.sms
      ) {
        return (this.disableSend = true);
      }

      if (this.message.channel.inbox || this.message.channel.email) {
        if (
          this.message.title.ka == null ||
          this.message.title.en == null ||
          this.message.title.ka == "" ||
          this.message.title.en == ""
        ) {
          return (this.disableSend = true);
        }
      }

      if (
        this.message.body.ka == null ||
        this.message.body.en == null ||
        this.message.body.ka == "" ||
        this.message.body.en == ""
      ) {
        return (this.disableSend = true);
      }

      return (this.disableSend = false);
    },
    spam() {
      var f = this.filters.replace("company/list", "");
      Api.message("spam", this.message, f).then(() => {
        this.$toast.ok(this.$vuetify.lang.t("$vuetify.message.sent_success"));
        this.closePopup();
      });
    },
  },
  watch: {
    popupNotification: function(newVal) {
      this.dialogState = newVal;
    },
  },
};
</script>
