<template>
  <v-dialog v-if="productData" persistent v-model="dialogState" width="95vw" max-width="1200px">
    <v-card min-height="800px">
      <v-toolbar color="accent" fixed>
        <v-toolbar-title>
          <span v-if="!limited">
          <v-icon left>mdi-pencil</v-icon>
          <span v-if="!ean14FormSwitch">{{$vuetify.lang.t('$vuetify.action.edit_product')}}</span>
          <span v-else>EAN-14</span>
          </span>
          <span v-else>
          <v-icon left>mdi-eye</v-icon>
          {{$vuetify.lang.t('$vuetify.action.view')}}
          </span>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-title class="headline"></v-card-title>
      <v-card-text>
        <h1>{{companyName}}</h1>
        <v-form ref="contactEditForm">
          <v-row align="start">
            <v-col cols="12" md="4" align="center">
              <v-row align="start">
                <v-col cols="12" md="4" align="center">
                  <base-image-input v-model="imageFile" :defaultImage="imageFile" v-on:fileToUplaod="fileToUplaod" :limited="limited"/>
                </v-col>
                <v-col cols="12" class="mt-5">
                  <v-btn v-if="imageFile" small @click="imageDelete()" color="error" class="mt-2 mr-2 mb-2">
                    {{ $vuetify.lang.t("$vuetify.action.delete_image") }}
                  </v-btn>
                  <v-autocomplete
                    :items="countryList"
                    v-model="productData.target_market"
                    item-text="text"
                    item-value="code"
                    :chips="true"
                    :multiple="true"
                    :deletable-chips="true"
                    :small-chips="true"
                    :label="$vuetify.lang.t('$vuetify.product.target_market')+' *'"
                    :readonly="productData.target_market == null"
                    @keyup="validateForm"
                    @change="validateForm"
                    :error="validator.target_market.error_show"
                    :error-messages="validator.target_market.error_msg"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" v-if="!limited">
                  <span
                    style="position:absolute; left:0; margin-left:25px;margin-top:-5px;"
                    >{{
                      $vuetify.lang.t("$vuetify.product.barcode_issue_date")
                    }}</span
                  >
                  <div
                    class="v-input v-input--hide-details theme--light v-text-field"
                  >
                    <div class="v-input__control">
                      <div
                        class="v-input__slot"
                        style="width:100%"
                        append-icon="mdi-magnify"
                      >
                        <div class="v-text-field__slot">
                          <datepicker
                            v-model="productData.issue_date"
                            :language="dateLanguage()"
                            :monday-first="true"
                          ></datepicker>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" v-if="!ean14FormSwitch">
                  <v-select v-if="productData.status != 'confirmed'"
                    v-model="productData.barcode_type"
                    :items="$i18n.messages[$i18n.locale]['$vuetify']['product']['barcode_types']"
                    :label="$vuetify.lang.t('$vuetify.product.barcode_type')+' *'"
                    :error="validator.barcode_type.error_show"
                    :error-messages="validator.barcode_type.error_msg"
                    @change="validateForm"
                    :readonly="limited"
                  ></v-select>
                  <div v-else style="text-align:left;">
                    <h3>{{$vuetify.lang.t('$vuetify.product.barcode')}}</h3> 
                    {{productData.barcode}}
                    <div v-if="limited">
                      <h3 class="mt-3">{{$vuetify.lang.t('$vuetify.product.barcode_issue_date')}}</h3>
                      {{productData.issue_date}}
                    </div>
                    <div class="mt-5" v-if="productDataOriginal.barcode_type=='ean13'"><v-btn small @click="ean14Form">EAN-14 {{$vuetify.lang.t('$vuetify.product.generate')}}</v-btn></div>
                  </div>
                </v-col>
                <v-col cols="12" class="mt-2" v-if="productData.barcode_type == 'itf14' || productData.barcode_type == 'ean14' || productData.barcode_type == 'sscc18' || ean14FormSwitch">
                  <v-text-field v-if="productData.status != 'confirmed' || ean14FormSwitch"
                    v-model="productData.barcode_packaging_level"
                    :label="$vuetify.lang.t('$vuetify.product.barcode_packaging_level')+' *'"
                    :error="validator.barcode_packaging_level.error_show"
                    :error-messages="validator.barcode_packaging_level.error_msg"
                    type="number"
                    @keyup="validateForm"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="8">
              <v-row align="start">
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="productData.brand.ka"
                    :label="$vuetify.lang.t('$vuetify.product.brand_name_ka')+' *'"
                    :error="validator.brand.ka.error_show"
                    :error-messages="validator.brand.ka.error_msg"
                    @keyup="validateForm"
                    :readonly="limited"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="productData.brand.en"
                    :label="$vuetify.lang.t('$vuetify.product.brand_name_en')+' *'"
                    :error="validator.brand.en.error_show"
                    :error-messages="validator.brand.en.error_msg"
                    @keyup="validateForm"
                    v-on:keypress="latinOnly($event)"
                    :readonly="limited"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="productData.name.ka"
                    :label="$vuetify.lang.t('$vuetify.product.name_ka')+' *'"
                    :error="validator.name.ka.error_show"
                    :error-messages="validator.name.ka.error_msg"
                    @keyup="validateForm"
                    :readonly="limited"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="productData.name.en"
                    :label="$vuetify.lang.t('$vuetify.product.name_en')+' *'"
                    :error="validator.name.en.error_show"
                    :error-messages="validator.name.en.error_msg"
                    @keyup="validateForm"
                    v-on:keypress="latinOnly($event)"
                    :readonly="limited"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="productData.type.ka"
                    :label="$vuetify.lang.t('$vuetify.product.type_ka')+' *'"
                    :error="validator.type.ka.error_show"
                    :error-messages="validator.type.ka.error_msg"
                    @keyup="validateForm"
                    :readonly="limited"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="productData.type.en"
                    :label="$vuetify.lang.t('$vuetify.product.type_en')+' *'"
                    :error="validator.type.en.error_show"
                    :error-messages="validator.type.en.error_msg"
                    @keyup="validateForm"
                    v-on:keypress="latinOnly($event)"
                    :readonly="limited"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="productData.volume_amount"
                    :label="$vuetify.lang.t('$vuetify.product.volume')+' *'"
                    :error="validator.volume_amount.error_show"
                    :error-messages="validator.volume_amount.error_msg"
                    @keyup="validateForm"
                    :readonly="limited"
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    v-model="productData.volume_unit"
                    :items="$i18n.messages[$i18n.locale]['$vuetify']['product']['values']"
                    :label="$vuetify.lang.t('$vuetify.product.volume')+' *'"
                    :error="validator.volume_unit.error_show"
                    :error-messages="validator.volume_unit.error_msg"
                    item-text="value"
                    item-value="key"
                    @keyup="validateForm"
                    @change="validateForm"
                    :readonly="limited"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="productData.package.ka"
                    :label="$vuetify.lang.t('$vuetify.product.item_packaging_ka')+' *'"
                    :error="validator.package.ka.error_show"
                    :error-messages="validator.package.ka.error_msg"
                    @keyup="validateForm"
                    :readonly="limited"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="productData.package.en"
                    :label="$vuetify.lang.t('$vuetify.product.item_packaging_en')+' *'"
                    :error="validator.package.en.error_show"
                    :error-messages="validator.package.en.error_msg"
                    @keyup="validateForm"
                    v-on:keypress="latinOnly($event)"
                    :readonly="limited"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="productData.gtin_segment_code"
                    :items="gtins.segment"
                    item-text="text"
                    item-value="code"
                    :error="validator.gtin_segment_code.error_show"
                    :error-messages="validator.gtin_segment_code.error_msg"
                    :label="$vuetify.lang.t('$vuetify.product.segment')+' *'"
                    @change="validateForm();collectGTIN('family','segment_code='+productData.gtin_segment_code)"
                    :readonly="limited"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="6" v-if="gtins.segment.length > 0 && gtins.family.length > 0">
                  <v-autocomplete
                    v-model="productData.gtin_family_code"
                    :loading="!gtins.family"
                    :items="gtins.family"
                    item-text="text"
                    item-value="code"
                    :label="$vuetify.lang.t('$vuetify.product.family')+' *'"
                    @change="validateForm();collectGTIN('class','segment_code='+productData.gtin_segment_code+'&family_code='+productData.gtin_family_code)"
                    :disabled="!productData.gtin_segment_code"
                    :readonly="limited"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="6" v-if="gtins.family.length > 0 && gtins.class.length > 0">
                  <v-autocomplete
                    v-model="productData.gtin_class_code"
                    :loading="!gtins.class"
                    :items="gtins.class"
                    item-text="text"
                    item-value="code"
                    :label="$vuetify.lang.t('$vuetify.product.class')+' *'"
                    @change="validateForm();collectGTIN('brick','segment_code='+productData.gtin_segment_code+'&family_code='+productData.gtin_family_code+'&class_code='+productData.gtin_class_code)"
                    :disabled="!productData.gtin_family_code"
                    :readonly="limited"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="6" v-if="gtins.class.length > 0 && gtins.brick.length > 0">
                  <v-autocomplete
                    v-model="productData.gtin_brick_code"
                    :loading="!gtins.brick"
                    :items="gtins.brick"
                    item-text="text"
                    item-value="code"
                    :label="$vuetify.lang.t('$vuetify.product.brick')+' *'"
                    @change="validateForm();collectGTIN('type','segment_code='+productData.gtin_segment_code+'&family_code='+productData.gtin_family_code+'&class_code='+productData.gtin_class_code+'&brick_code='+productData.gtin_brick_code)"
                    :disabled="!productData.gtin_class_code"
                    :readonly="limited"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="6"  v-if="gtins.brick.length > 0 && gtins.type.length > 0">
                  <v-autocomplete
                    v-model="productData.gtin_type_code"
                    :items="gtins.type"
                    item-text="text"
                    item-value="code"
                    :label="$vuetify.lang.t('$vuetify.product.type')+' *'"
                    @change="validateForm();collectGTIN('value','segment_code='+productData.gtin_segment_code+'&family_code='+productData.gtin_family_code+'&class_code='+productData.gtin_class_code+'&brick_code='+productData.gtin_brick_code+'&type_code='+productData.gtin_type_code)"
                    :disabled="!productData.gtin_brick_code"
                    :readonly="limited"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="6"  v-if="gtins.type.length > 0 && gtins.value.length > 0">
                  <v-autocomplete
                    v-model="productData.gtin_value_code"
                    :items="gtins.value"
                    item-text="text"
                    item-value="code"
                    :label="$vuetify.lang.t('$vuetify.product.value')+' *'"
                    :disabled="!productData.gtin_brick_code"
                    :readonly="limited"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn @click="cancelEdit">
          <v-icon class="mr-3">mdi-close</v-icon>
          <span v-if="!limited">{{$vuetify.lang.t('$vuetify.action.cancel')}}</span>
          <span v-else>{{$vuetify.lang.t('$vuetify.action.close')}}</span>
        </v-btn>

        <v-btn @click="updateProduct()" v-if="!limited || ean14FormSwitch">
          <v-icon class="mr-3">mdi-content-save</v-icon>
          <span v-if="!ean14FormSwitch">{{$vuetify.lang.t('$vuetify.action.update')}}</span>
          <span v-else>EAN-14 {{$vuetify.lang.t('$vuetify.product.generate')}}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Api from "@/services/api";
import BaseImageInput from "@/components/shared/BaseImageInput";
import ProductHelper from "@/helpers/product";
import Countries from "@/plugins/countries";
import Datepicker from "vuejs-datepicker";
import { en, ge } from "vuejs-datepicker/dist/locale";
export default {
  components: {
    Datepicker,
    BaseImageInput
  },
  data: function() {
    return {
      ean14FormSwitch: false,
      limited: this.$route.path=='/customer/products',
      countryList: Countries.forLanguage(this.$i18n.locale),
      dialogState: false,
      productData: false,
      productDataOriginal: false,
      imageFile: null,
      validator: ProductHelper.validatorModel(),
      newImage: false,
      companyName: null,
      barcode_date: null,
      deleteImage: false,
      gtins: {
        segment: this.$i18n.messages[this.$i18n.locale]['$vuetify']['segments'],
        family: [],
        class: [],
        brick: [],
        type: [],
        value: []
      }
    };
  },
  watch: {
    "$i18n.locale": function() {
      this.gtins.segment = this.$i18n.messages[this.$i18n.locale]['$vuetify']['segments']
    },
    popupEditProduct: function(newVal) {
      this.dialogState = newVal;
    },
    product: function(newVal) {
      this.productData = false
      Api.product("get", newVal)
        .then(({ data }) => {
          this.companyName =  data.company.name[this.$i18n.locale]
          this.barcode_date = data.barcode_date
          this.productData = Object.assign({}, data);
          this.productDataOriginal = Object.assign({}, data);
          this.imageFile = this.productData.image;
          let p = this.productData.package != null ? this.productData.package : {ka:null,en:null}
          this.productData.package = p;

          if(this.productData.gtin_family_code != null) {
            this.collectGTIN('family','segment_code='+this.productData.gtin_segment_code)
          }

          if(this.productData.gtin_family_code != null) {
            this.collectGTIN('class','segment_code='+this.productData.gtin_segment_code+'&family_code='+this.productData.gtin_family_code)
          }

          if(this.productData.gtin_brick_code != null) {
            this.collectGTIN('brick','segment_code='+this.productData.gtin_segment_code+'&family_code='+this.productData.gtin_family_code+'&class_code='+this.productData.gtin_class_code)
          }

          if(this.productData.gtin_type_code != null) {
            this.collectGTIN('type','segment_code='+this.productData.gtin_segment_code+'&family_code='+this.productData.gtin_family_code+'&class_code='+this.productData.gtin_class_code+'&brick_code='+this.productData.gtin_brick_code)
          }

          if(this.productData.gtin_value_code != null) {
            this.collectGTIN('value','segment_code='+this.productData.gtin_segment_code+'&family_code='+this.productData.gtin_family_code+'&class_code='+this.productData.gtin_class_code+'&brick_code='+this.productData.gtin_brick_code+'&type_code='+this.productData.gtin_type_code)
          }
        })
        .catch(e => {
          console.log(e);
        });
    }
  },
  methods: {
    imageDelete() {
      this.deleteImage = true,
      this.imageFile = null
    },
    dateLanguage() {
      var dateLocales = new Array();
      dateLocales["en"] = en;
      dateLocales["ka"] = ge;
      return dateLocales[this.$i18n.locale];
    },
    ean14Form() {
      this.ean14FormSwitch = true;
    },
    collectGTIN(part, data) {
      Api.gtin(part, data).then(data => {
        this.gtins[part] = data.data;
      });
    },
    cancelEdit() {
      this.dialogState = false;
      this.ean14FormSwitch = false;
      this.productData = Object.assign({}, this.productDataOriginal);
      this.$emit('closeEditProductPopup')
      this.ean14FormSwitch = false;
      this.deleteImage = false;
      this.imageFile = this.productData.image ?? null
    },
    validateForm(hasError=true) {
      this.validator = ProductHelper.validateForm(this.productData,hasError);
    },
    fileToUplaod(img) {
      this.newImage = true;
      this.validateForm;
      this.productData.image = img;
    },
    updateProduct: function() {
      if(this.ean14FormSwitch) {
        return this.addProduct();
      }

      this.validateForm(false);
      if(this.validator.hasError) {
        return
      }
      if(!this.newImage) {
        delete this.productData.image;
      } 

      if(!this.limited) {
        this.productData.status = 'confirmed';
      }

      if(this.deleteImage) {
        this.productData.image_delete = true;
      }

      Api.product('update', this.productData).then(data => {
        this.$emit('closeEditProductPopup', data)
        this.$emit('reload', data)
        this.deleteImage = false;
        this.imageFile = this.productData.image ?? null
      });
    },
    addProduct: function() {
      this.productData.barcode_parent = this.productData.barcode
      this.productData.barcode_type = "itf14"
      delete this.productData.id;
      delete this.productData.barcode;
      Api.product("create", this.productData).then(data => {
        this.$emit('closeEditProductPopup', data)
        this.$emit('reload', data)
        if(this.ean14FormSwitch) {
          this.ean14FormSwitch = false;
          this.$toast.ok(this.$vuetify.lang.t("$vuetify.action.product_request_success"));
        }
      });
    }
  },
  props: ["product", "popupEditProduct"]

};
</script>