<template>
  <div style="max-width:100%; overflow:hidden;" :class="noMinHeight ? '' : 'minHeight'">
    <div class="companyNameTop mb-5">
      <router-link v-if="companyName" :to="'/manager/company/'+$route.params.id">{{companyName}}</router-link>
    </div>
  <v-card>
    <v-toolbar color="accent" fixed v-if="title">
      <v-toolbar-title style="width:100%">
        <div class="float-left pt-2">
          <h6><v-icon color="primary" class="mt-n1">{{icon}}</v-icon>
          <span class="mt-n2"> {{title}} <router-link class="companyName" v-if="companyName" :to="'/manager/company/'+$route.params.id">{{companyName}}</router-link></span></h6>
        </div>
        <div class="float-right">
          <v-btn
            small
            v-if="tableType=='company'"
            color="primary pr-5"
            @click="dialogAddCompanyPopup = true"
          >
            <v-icon class="mr-2">mdi-plus</v-icon>
            {{$i18n.t('$vuetify.action.add')}}
          </v-btn>
          <v-btn
            v-if="tableType=='product' && (tableUser=='customer' || showAddProduct)"
            color="primary pr-5"
            @click="$emit('add-product')"
          >
            <v-icon class="mr-2">mdi-plus</v-icon>
            <span>{{$i18n.t('$vuetify.action.add')}}</span>
          </v-btn>
          <v-btn
            small
            v-if="tableType=='administrators'"
            color="primary pr-5"
            @click="openDialog('addManager')"
          >
            <v-icon class="mr-2">mdi-plus</v-icon>
            {{$i18n.t('$vuetify.action.add')}}
          </v-btn>
          <v-btn
            small
            v-if="tableType=='customers'"
            color="primary pr-5"
            @click="openDialog('addUser')"
          >
            <v-icon class="mr-2">mdi-plus</v-icon>
            {{$i18n.t('$vuetify.action.add')}}
          </v-btn>
        </div>
      </v-toolbar-title>
      <v-menu offset-y :nudge-left="170" :close-on-content-click="false">
        <v-btn icon v-slot:activator="{}">
          <v-icon>more_vert</v-icon>
        </v-btn>
        <v-list>
          <v-list-tile
            v-for="(item, index) in headers"
            :key="item.value"
            @click="changeDesc"
            :id="index"
          >
            <v-list-tile-title>
              {{ item.text }}
              <v-icon
                v-if="options.sortBy === item.value"
              >{{options.descending ? 'arrow_downward':'arrow_upward'}}</v-icon>
            </v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-layout v-resize="onResize" column style="padding-top:10px">
      <div v-if="(tableType == 'product') || (tableType == 'company' && !hideFilters) || (tableType == 'payments' && !this.$route.params.id)" class="ml-2">
        <v-btn :disabled="tableFilter==null" @click="tableFilter=null; ++reloadSelf" class="mr-2 mb-2">{{$vuetify.lang.t('$vuetify.tableFilter.all')}}</v-btn>
        <v-btn :disabled="tableFilter=='active' || tableFilter=='confirmed'" @click="tableFilter='active'; ++reloadSelf" class="mr-2 mb-2">{{$vuetify.lang.t('$vuetify.tableFilter.active')}}</v-btn>
        <v-btn v-if="tableType != 'product'" :disabled="tableFilter=='excluded'" @click="tableFilter='excluded'; ++reloadSelf" class="mr-2 mb-2">{{$vuetify.lang.t('$vuetify.tableFilter.excluded')}}</v-btn>
        <v-btn :disabled="tableFilter=='review'" @click="tableFilter='review'; ++reloadSelf" class="mr-2 mb-2">{{$vuetify.lang.t('$vuetify.tableFilter.review')}}</v-btn>
      </div>

      <div v-if="tableType == 'mass'" class="ml-2">
        <v-btn :disabled="$route.params.inbox" to="/manager/mass/inbox" class="mr-2 mb-2">Inbox</v-btn>
        <v-btn :disabled="$route.params.sms" to="/manager/mass/sms" class="mr-2 mb-2">Sms</v-btn>
        <v-btn :disabled="$route.params.email" to="/manager/mass/email" class="mr-2 mb-2">E-mail</v-btn>
      </div>
      <v-container class="container-fluid pr-5 pl-5" style="max-width:100% !important">

        <v-row v-if="tableType=='company'">
          <v-col >
            <v-autocomplete 
              :label="$i18n.t('$vuetify.company.production_field')"
              v-model="filterFields"
              :items="fields"
              item-text="name"
              item-value="key"
              multiple
              chips
              deletable-chips
              small-chips></v-autocomplete>
          </v-col>
        </v-row>
        <v-row justify="end" v-if="!hideFilters">
          <v-col md="2" v-if="tableType == 'company'">
            <v-checkbox
              v-model="hasDebt"
              :label="$vuetify.lang.t('$vuetify.tableFilter.hasDebt')"
            ></v-checkbox>
          </v-col>
          <v-col cols="12" md="2">
            <div class="v-input v-input--hide-details theme--light v-text-field">
              <div class="v-input__control">
                <div class="v-input__slot" style="width:fit-content" append-icon="mdi-magnify">
                  <div class="v-text-field__slot">
                    <datepicker
                      v-model="fromSelected"
                      :language="dateLanguage"
                      :monday-first="true"
                      :placeholder="$vuetify.lang.t('$vuetify.tables.date_from')"
                      :disabled-dates="disabledDatesFrom"
                        :highlighted="currentDate"
                    ></datepicker>
                    <v-icon @click="clearFrom()" v-if="fromSelected != null">mdi-close</v-icon>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="2">
            <div class="v-input v-input--hide-details theme--light v-text-field">
              <div class="v-input__control">
                <div class="v-input__slot" style="width:fit-content" append-icon="mdi-magnify">
                  <div class="v-text-field__slot">
                    <datepicker
                      v-model="toSelected"
                      :language="dateLanguage"
                      :monday-first="true"
                      :placeholder="$vuetify.lang.t('$vuetify.tables.date_to')"
                      :disabled-dates="disabledDatesTO"
                      :highlighted="currentDate"
                    ></datepicker>
                    <v-icon @click="clearTo()" v-if="toSelected != null">mdi-close</v-icon>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :placeholder="$vuetify.lang.t('$vuetify.input.search')"
              single-line
              hide-details
              @keyup.enter="fetchRecords"
              :clearable="true"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <v-data-table
        :headers="headers"
        :items="records"
        :options.sync="options"
        :hide-default-headers="isMobile"
        :class="{mobile: isMobile}"
        :server-items-length="totalItems"
        :footer-props="footerProps"
        :items-per-page="itemsPerPageSent ? itemsPerPageSent : 50"
        :loading="isLoading"
      >
        <template v-slot:item.selectable="{item}">
          <v-checkbox
            v-model="selectedItems"
            :value="item.id"
          ></v-checkbox>
        </template>

        <template v-slot:item.barcode_type="{item}">
          {{$i18n.messages[$i18n.locale]['conversions'][item.barcode_type]}}
        </template>

        <template v-slot:[`item.image`]="{ item }">
          <img
            v-if="item.image != null"
            :src="item.image+'?'+item.update_date"
            style="max-width:50px; max-height:50px;"
            class="mt-5 mb-5"
          />
          <img v-else :src="require(`@/assets/img/noimage.png`)"
            style="max-width:50px; max-height:50px;"
            class="mt-5 mb-5"/>
        </template>

        <template v-slot:item.barcode="{ item }">
          {{item.barcode}}
        </template>

        <template v-slot:item.barcode_type="{ item }">
          {{$i18n.messages[$i18n.locale]["conversions"][item.barcode_type]}}
        </template>

        <template v-slot:item.name="{ item }">
          <router-link  v-if="tableType!='product' && tableType!='product-review' && tableType!='company-review'" :to="'/manager/'+tableType+'/'+item.id" >{{ item.name[$i18n.locale] }}</router-link>
          <router-link  v-else-if="tableType=='company-review'" :to="'/manager/company/'+item.id" >{{ item.name[$i18n.locale] }}</router-link>
          <span v-else>{{ item.name[$i18n.locale] }}</span>
        </template>

        <template v-slot:item.company.email="{item}">
          <div class="limitedText" v-if="item.company.email">{{item.company.email}}</div>
          <div class="limitedText" v-else>{{item.user.email}}</div>
        </template>

        <template v-slot:item.viewNotification="{item}">
          <v-icon @click="dialogViewNotification=true;notificationData=item">mdi-eye</v-icon>
        </template>

        <template v-slot:item.custom_title="{item}">
          <div class="limitedText" v-if="item.custom_title != null">{{item.custom_title[$i18n.locale]}}</div>
          <div class="limitedText" v-else-if="item.template.subject != null">{{item.template.subject[$i18n.locale]}}</div>
          <div class="limitedText" v-else-if="item.custom_subject != null">{{item.custom_subject[$i18n.locale]}}</div>
        </template>

        <template v-slot:item.title="{item}">
          <div v-if="item.title">{{item.title[$i18n.locale]}}</div>
        </template>

        <template v-slot:item.brand="{ item }">
          {{ item.brand[$i18n.locale] }}
        </template>

        <!-- <template v-slot:item.company_name="{ item }">
          <router-link
            :to="'/manager/companys/'+item.company_id"
            
          >{{ item.company_name[$i18n.locale] }}</router-link>
        </template> -->

        <template v-slot:item.editProduct="{ item }">
          <v-icon @click="dialogEditProduct=true;productData=item.id">mdi-pencil</v-icon>
        </template>

        <template v-slot:item.removeProduct="{ item }">
          <v-icon @click="dialogRemoveProductPopup=true;dpdata=item" v-if="tableType == 'product-review' || (tableType=='product' && (item.status == 'განსახილველი' || item.status=='Review'))">mdi-delete</v-icon>
        </template>

        <template v-slot:item.removeCompany="{ item }">
          <v-icon @click="dialogRemoveCompanyPopup=true;dpdata=item" v-if="tableType == 'company-review' || (tableType=='company' && (item.status == 'განსახილველი' || item.status=='Review'))">mdi-delete</v-icon>
        </template>

        <template v-slot:item.viewProduct="{item}">
          <v-icon @click="dialogEditProduct=true;productData=item.id">mdi-eye</v-icon>
        </template>

        <template v-slot:item.volume_amount="{item}">
          {{item.volume_amount}} {{$i18n.messages[$i18n.locale]["$vuetify"]["product"]['value_translations'][item.volume_unit]}}
        </template>

        <template v-slot:item.package="{item}">
          {{item.package[$i18n.locale]}}
        </template>

        <template v-slot:item.invoiceActions="{item}">
          <v-icon v-if="!tableUser" @click="openDialog('invoiceEdit',item)" class="mr-2" :title="$vuetify.lang.t('$vuetify.action.edit')">mdi-pencil</v-icon>
          <v-icon @click="invoiceDownload(item.id)" class="mr-2" :title="$vuetify.lang.t('$vuetify.action.download')">mdi-download</v-icon>
        </template>

        <template v-slot:item.newsActions="{item}">
          <v-icon @click="toggleNews(item)" class="mr-2" >{{item.hidden == true ? 'mdi-eye' : 'mdi-eye-off'}}</v-icon>
          <v-icon @click="$parent.newsImagePopupShow(item)" class="mr-2" >mdi-image-plus</v-icon>
          <v-icon @click="$parent.newsPopupShow(item)" class="mr-2" >mdi-pencil</v-icon>
          <v-icon @click="dialogRemoveNewsPopup=true;dpdata=item" class="mr-2">mdi-delete</v-icon>
        </template>

        <template v-slot:item.companies="{ item }">
          <div v-if="typeof item.companies[0] != 'undefined'">
          <router-link v-if="!tableUser"
            :to="'/manager/company/'+item.companies[0]['id']"
          >{{ item.companies[0]['name'][$i18n.locale] }}</router-link>
          <span v-else>{{ item.companies[0]['name'][$i18n.locale] }}</span>
          </div>
        </template>

        <template v-slot:item.company.name="{ item }">
          <router-link  v-if="!tableUser" :to="'/manager/company/'+item.company.id">{{ item.company.name }}</router-link>
          <span v-else>{{ item.company.name }}</span>
        </template>

        <template v-slot:item.editTransaction="{ item }">
          <v-icon @click="dialogEditTransaction=true;transactionData=item">mdi-pencil</v-icon>
        </template>

        <template v-slot:item.editUser="{ item }">
          <v-icon @click="openDialog('editUser',item)">mdi-pencil</v-icon>
        </template>

        <template v-slot:[`item.editAdministrator`]="{ item }">
          <v-icon @click="openDialog('editManager',item)">mdi-pencil</v-icon>
          <v-icon class="ml-1" @click="openDialog('activity',item.id)">mdi-account-search</v-icon>
        </template>


      </v-data-table>
    </v-layout>
    <v-card-actions v-if="tableType != 'activityLog' && tableType != 'invoices' && tableType != 'customers' && tableType != 'administrators' && tableType != 'product-review' && tableType != 'company-review'">
      <v-row>
        <v-col cols="12" v-if="tableType!='mass' && tableType!='news'">
          <v-btn
            @click="excellDownload"
            small
            color="default"
            :loading="isLoading"
            class="mr-2 mb-1"
            v-if="tableType!='product' || $route.params.id || selectedItemsString.length >= 2 || tableUser=='customer'"
          >{{$vuetify.lang.t('$vuetify.buttons.download_table')}}</v-btn>

          <v-btn v-if="tableType=='company' && tableUser!='customer'" 
            small
            color="default"
            :loading="isLoading"
            class="mb-1" 
            @click="dialogNotificationPopup = true"
            >{{$i18n.t('$vuetify.navigations.mass_send')}}</v-btn>
        </v-col>
        <v-col cols="12" v-if="tableType=='company' || tableType=='product' && tableUser!='customer'">
          <v-btn  
            small
            color="default"
            :loading="isLoading"
            class="mr-2 mb-1"
            @click="downloadGephir()"
            >GEPIR {{$vuetify.lang.t('$vuetify.buttons.file')}}</v-btn>
          <v-btn  
            small
            color="default"
            :loading="isLoading"
            class="mr-2 mb-1"
            @click="tableType=='company' ? gepirCompany() : gepirProduct()"
            >GEPIR {{$vuetify.lang.t('$vuetify.buttons.validate')}}</v-btn>
          <v-btn  
            small
            color="default"
            :loading="isLoading"
            class="mr-2 mb-1"
            @click="tableType=='company' ? gepirCompany(true) : gepirProduct(true)"
            >GEPIR {{$vuetify.lang.t('$vuetify.action.upload')}}</v-btn>
        </v-col>
        <v-col cols="12">
          <template v-if="($route.params.id || tableUser=='customer') && this.tableType=='product'">
            <v-btn class="mr-5" @click="getBarcode('eps')" small color="default" :loading="isLoading">EPS</v-btn>
            <v-btn class="mr-5" v-if="tableUser != 'customer'" @click="getBarcode('png')" small color="default" :loading="isLoading">PNG</v-btn>
            <v-btn @click="getBarcode('pdf')" small color="default" :loading="isLoading">PDF</v-btn>
          </template>
        </v-col>
      </v-row>
    </v-card-actions>

    <EditProduct
      v-if="tableType=='product' || tableType=='product-review'"
      :product="productData"
      :popupEditProduct="dialogEditProduct"
      v-on:closeEditProductPopup="dialogEditProduct=false"
      v-on:reload="fetchRecords"
    />

    <RemoveProduct
      :removeProductPopup="dialogRemoveProductPopup"
      :dpdata="dpdata"
      v-on:closeRemoveProductPopup="dialogRemoveProductPopup=false"
      v-on:reload="fetchRecords" 
    />

    <RemoveNews
      :removeNewsPopup="dialogRemoveNewsPopup"
      :dpdata="dpdata"
      v-on:closeRemoveNewsPopup="dialogRemoveNewsPopup=false"
      v-on:reload="fetchRecords" 
    />

    <RemoveCompany
      :removeProductPopup="dialogRemoveCompanyPopup"
      :dpdata="dpdata"
      v-on:closeRemoveCompanyPopup="dialogRemoveCompanyPopup=false"
      v-on:reload="fetchRecords" 
    />

    <EditTransaction
      :transaction="transactionData"
      :popupEditTransaction="dialogEditTransaction"
      v-on:closeEditTransactionPopup="dialogEditTransaction=false"
      v-on:reload="fetchRecords"
    />

    <AddCompany
      :addCompanyPopup="dialogAddCompanyPopup"
      v-on:closeAddCompanyPopup="dialogAddCompanyPopup=false"
    />

    <Download 
      :popupDownload="dialogDownload"
      :urlDownload="downloadZip"
      v-on:close-download="dialogDownload=false"
    />

    <Notification 
      :popupNotification="dialogNotificationPopup"
      :filters="query"
      v-on:close-notification="dialogNotificationPopup=false"
    />

    <ViewNotification :popupNotification="dialogViewNotification"
      :notificationData="notificationData"
      v-on:close="dialogViewNotification=false"
    />
  </v-card>
  </div>
</template>

<script>
import Api from "@/services/api";
import Datepicker from "vuejs-datepicker";
import { en, ge } from "vuejs-datepicker/dist/locale";
import EditProduct from "@/components/shared/popup/EditProduct";
import RemoveProduct from "@/components/manager/popup/RemoveProduct";
import RemoveNews from "@/components/manager/popup/RemoveNews";
import RemoveCompany from "@/components/manager/popup/RemoveCompany";
import EditTransaction from "@/components/manager/popup/EditTransaction";
import AddCompany from "@/components/manager/popup/AddCompany";
import Download from "@/components/manager/popup/Download";
import Notification from "@/components/manager/popup/Notifications";
import ViewNotification from "@/components/manager/popup/ViewNotification";
//import axios from "axios";

var dateLocales = new Array();
dateLocales["en"] = en;
dateLocales["ka"] = ge;

export default {
  components: {
    Datepicker,
    EditProduct,
    EditTransaction,
    AddCompany,
    Download,
    RemoveProduct,
    RemoveCompany,
    Notification,
    ViewNotification,
    RemoveNews
  },
  data: function() {
    return {
      currentDate:{ dates: [new Date(new Date().toISOString().slice(0, 10))]},
      hasDebt: false,
      downloadZip: false,
      dialogDownload: false,
      barcodeURL: null,
      dialogAddCompanyPopup: false,
      dialogRemoveProductPopup: false,
      dialogRemoveNewsPopup: false,
      dialogRemoveCompanyPopup: false,
      API_URL: process.env.VUE_APP_API_BASE_URL,
      fromSelected: null,
      toSelected: null,
      disabledDatesFrom: {
        from: ""
      },
      disabledDatesTO: {
        to: ""
      },
      fromDate: "",
      toDate: "",
      totalItems: 0,
      footerProps: {
        "items-per-page-options": [5, 10, 20, 50, 100]
      },
      options: {},
      search: this.$route.params.companyName
        ? this.$route.params.companyName
        : "",
      isMobile: false,
      records: this.initialData,
      isLoading: false,
      dialogEditProduct: false,
      productData: false,
      dpdata: false,
      dialogEditTransaction: false,
      transactionData: false,
      tableFilter: 'active',
      reloadSelf: 0,
      selectedItems: [],
      selectedItemsString: '',
      filterFields: '',
      fields: this.getFields(),
      dialogNotificationPopup: false,
      query: '',
      dialogViewNotification: false,
      notificationData: {}
    };
  },
  computed: {
    loc() {
      return this.$i18n.locale;
    },
    dateLanguage() {
      return dateLocales[this.$i18n.locale];
    },
    getCsvHeaders() {
      var h = {};
      for (var i = 0; i < this.headers.length; i++) {
        var t = this.headers[i]["text"];
        var f = this.headers[i]["value"];
        h[t] = f;
      }
      return h;
    }
  },
  props: [
    "title",
    "headers",
    "sortByKey",
    "sortDesc",
    "itemsPerPageSent",
    "servicePath",
    "initialData",
    "icon",
    "orgID",
    "tableType",
    "reload",
    "tableUser",
    "showAddProduct",
    "hideFilters",
    "companyName",
    "noMinHeight"
  ],
  watch: {
    title(newVal) {
      this.title = newVal
    },
    hasDebt() {
      this.fetchRecords();
    },
    selectedItems() {
      this.selectedItemsString = ''
      this.selectedItems.forEach(e => 
        this.selectedItemsString += '&product_id='+e
      );
    },
    reloadSelf() {
      this.fetchRecords();
    },
    reload() {
      this.fetchRecords();
    },
    options: {
      handler() {
        this.fetchRecords();
      },
      deep: true
    },
    loc: {
      handler() {
        this.fetchRecords();
      },
      deep: true
    },
    filterFields() {
      this.fetchRecords();
    },
    $route() {
      this.tableFilter = 'active';
      this.search = this.$route.params.companyName
        ? this.$route.params.companyName
        : "";
      this.fetchRecords();
    },
    fromSelected: function() {
      return this.dateFromSelected();
    },
    toSelected: function() {
      return this.dateToSelected();
    },
    search: function() {
      if (this.search == null) {
        this.search = "";
        return this.fetchRecords();
      }
    }
  },
  methods: {
    getFields() {
      var f = [];
      let locFieldsArray = this.$i18n.messages[this.$i18n.locale].$vuetify.production_fields
      for (var fkey in locFieldsArray) {
        f.push({
          name: locFieldsArray[fkey],
          key: fkey
        });
      }
      return f;
    },
    downloadGephir() {
      return new Promise(resolve => {
        this.isLoading = true;
        Api.download('gephir',this.tableType).then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", 'gephir-'+ this.tableType + ".xlsx");
          document.body.appendChild(fileLink);

          fileLink.click();

          this.isLoading = false;
          resolve();
        });
      });
    },
    openDialog(d, i) {
      if (i) {
        return this.$emit(d, i);
      }
      this.$emit(d);
    },
    clearTo() {
      this.toSelected = null;
      this.disabledDatesFrom.from = null;
    },
    clearFrom() {
      this.fromSelected = null;
      this.disabledDatesTO.to = null;
    },
    dateFromSelected() {
      this.fromDate =
        this.fromSelected == null ? "" : this.dateParse(this.fromSelected);
      this.disabledDatesTO.to = this.fromDate == "" ? null : this.fromSelected;
      return this.fetchRecords();
    },
    dateToSelected() {
      this.toDate =
        this.toSelected == null ? "" : this.dateParse(this.toSelected);
      this.disabledDatesFrom.from =
        this.toDate == null ? null : this.toSelected;
      return this.fetchRecords();
    },
    dateParse(d) {
      let df =
        d.getUTCFullYear() +
        "/" +
        ("0" + (d.getUTCMonth() + 1)).slice(-2) +
        "/" +
        ("0" + d.getUTCDate()).slice(-2);
      return df;
    },
    changeDesc() {
      this.options.descending = true;
    },
    invoiceDownload(id) {
      return new Promise(resolve => {
        Api.download("invoice", '/export/'+id+'?locale='+this.$i18n.locale).then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", id + "-invoice.pdf");
          document.body.appendChild(fileLink);

          fileLink.click();

          this.isLoading = false;
          resolve();
        });
      });
    },
    excellDownload() {
      return new Promise(resolve => {
        this.isLoading = true;
        this.queryConstructor(
          process.env.VUE_APP_API_BASE_URL + this.servicePath + "/export"
        );

        if(this.selectedItemsString.length >= 2) {
          this.query += this.selectedItemsString
        }

        if(this.tableType == 'product' && this.tableUser == 'customer') {
          this.query += "&company_id="+JSON.parse(localStorage.user).company_id
        }

        this.query += "&exportLocale="+this.$i18n.locale;

        Api.download("companyList", this.query).then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", this.title + "-full.xls");
          document.body.appendChild(fileLink);

          fileLink.click();

          this.isLoading = false;
          resolve();
        });
      });
    },
    fetchRecords() {
      return new Promise(resolve => {
        this.isLoading = true;
        this.queryConstructor(this.servicePath);
        Api.table(this.query).then(({ data }) => {

          if(typeof data.items == 'undefined') {
            this.totalItems = 0;
            this.isLoading = false;
            resolve();
            return;
          }

          let items = data.items;
          const totalItems = data.total;
          this.records = this.tableType === 'news' ? items : this.translateFields(items);
          this.totalItems = totalItems;
          this.isLoading = false;
          resolve();
        });
      });
    },
    toggleNews(item) {
      item.hidden = item.hidden ? false : true;
      Api.article("save", item).then(() => {
          this.$toast.ok(
            this.$vuetify.lang.t("$vuetify.action.update_success")
          );
          ++this.reload
        });
    },
    gepirCompany(upload) {
      this.isLoading = true;
      var act = upload ? 'gepir/upload' : 'gepir/validate';
      var msg = upload ? this.$vuetify.lang.t("$vuetify.action.upload_success") : this.$vuetify.lang.t("$vuetify.action.valid_file")
      Api.company(act).then(({ data }) => {
        this.isLoading = false;
        if(data.errors) {
          return alert(JSON.stringify(data.errors))
        }
        this.$toast.ok(msg);
      });
    },
    gepirProduct(upload) {
      this.isLoading = true;
      var act = upload ? 'gepir/upload' : 'gepir/validate';
      var msg = upload ? this.$vuetify.lang.t("$vuetify.action.upload_success") : this.$vuetify.lang.t("$vuetify.action.valid_file")
      Api.product(act).then(({ data }) => {
        this.isLoading = false;
        if(data.errors) {
          return alert(JSON.stringify(data.errors))
        }
        this.$toast.ok(msg);
      });
    },
    getBarcode(format) {
      this.dialogDownload = true;
      return this.barcodeUrl(format)
    },
    barcodeUrl(format) {
      this.isLoading = true;
      let search = this.search != "" ? this.search.trim().toLowerCase() : "";
      let companyID = typeof JSON.parse(localStorage.user).company_id == 'undefined' ? this.$route.params.id : JSON.parse(localStorage.user).company_id;
      let q = "searchVal=" +
        search +
        "&dateFrom=" +
        this.fromDate +
        "&dateTo=" +
        this.toDate + 
        "&company_id="+companyID+
        "&image_type="+format +
        this.selectedItemsString

      Api.barcode('download', q).then((res) => {
        this.isLoading=false;
        this.downloadZip = res.data.url
      });
    },
    queryConstructor(path) {
      let { sortBy, sortDesc, page, itemsPerPage } = this.options;
      let search = this.search != "" ? this.search.trim().toLowerCase() : "";

      if (
        sortBy == "name" ||
        sortBy == "company.name" ||
        sortBy == "brand" ||
        sortBy == "type"
      ) {
        sortBy += "." + this.$i18n.locale;
      }

      if(this.tableType == 'product-review' || this.tableType == 'company-review') {
        itemsPerPage = itemsPerPage ?? 10;
        sortBy = 'id';
        sortDesc = true;
        this.tableFilter = 'review';
      }

      this.isLoading = true;
      this.query =
        path +
        "?page=" +
        page +
        "&sortBy=" +
        sortBy +
        "&sortDesc=" +
        sortDesc +
        "&itemsPerPage=" +
        itemsPerPage;

      if(!this.hideFilters) {
         this.query +=
        "&dateFrom=" +
        this.fromDate +
        "&dateTo=" +
        this.toDate;
      } 

      if(search) {
        this.query += "&searchVal=" +search;
      }

      this.query += this.orgID ? "&orgID=" + this.orgID : "";

      if (this.tableType == "customers") {
        this.query += "&role=customer";
      }

      if (this.tableType == "administrators") {
        this.query += "&role=manager&role=finance";
      }

      if(this.tableType == 'payments' && this.$route.params.type) {
        this.query += "&payment_type="+this.$route.params.type;
      } else if((this.tableType == 'company' || this.tableType == 'product-review' || this.tableType == 'product' || this.tableType == 'company-review') && this.tableFilter != null) {
        
        this.tableFilter = this.tableFilter == 'active' && this.tableType == 'product' ? 'confirmed' : this.tableFilter
        this.query += "&status="+this.tableFilter;
      }

      if(this.tableType == 'payments' && this.$route.params.id) {
        this.query += "&company_id="+this.$route.params.id;
      } else if(this.tableType == 'payments' && this.tableFilter != null && !this.$route.params.id) {
        this.query += "&status="+this.tableFilter;
      }

      if(this.tableType == 'product' && this.$route.params.id) {
        this.query += "&company_id="+this.$route.params.id;
      }

      if(this.tableType == 'invoices') {
        this.path = this.$route.path.replace('/manager/invoices/','')
        this.path = this.path.replace('/customer/invoices/','')
        this.path = this.path.replace( /[0-9]/g, '')
        this.path = this.path.replace('/','')
        this.query += "&invoice_type="+this.path;

        if(this.$route.params.id) {
          this.query += '&company_id='+this.$route.params.id
        }
      }

      if(this.hasDebt) {
        this.query += '&has_debt=true'
      }

      if(this.filterFields) {
        this.query += '&main_production_field='+this.filterFields
      }

    },
    translateFields(items) {
      var translatedFields = items;
      for (var i = 0; i < items.length; i++) {
        //translated fields
        translatedFields[i] = items[i];
        if (items[i]["main_production_field"]) {
          translatedFields[i]["main_production_field"] = this.$i18n.messages[
            this.$i18n.locale
          ]["$vuetify"]["production_fields"][items[i]["main_production_field"]];
        }
        if (items[i]["title"]) {
          translatedFields[i]["title"] = items[i]["title"][this.$i18n.locale];
        }
        if (items[i]["company"]) {
          translatedFields[i]["company"]["name"] = items[i]["company"]["name"]
            ? items[i]["company"]["name"][this.$i18n.locale]
            : "";
        }

        if (items[i]["type"]) {
          items[i]["type"] = items[i]["type"][this.$i18n.locale];
        }

        //transaction types
        if (items[i]["payment_type"]) {
          translatedFields[i]["payment_type_selected"] = {
            name: this.$i18n.messages[this.$i18n.locale]["$vuetify"][
              "payment_types"
            ][items[i]["payment_type"]],
            key: "" + items[i]["payment_type"]
          };
          translatedFields[i].payment_type = this.$i18n.messages[
            this.$i18n.locale
          ]["$vuetify"]["payment_types"][items[i]["payment_type"]];
          translatedFields[i].item_id = i;
        }
        //status
        if (items[i]["status"]) {
          translatedFields[i]["status_key"] = items[i]["status"];
          translatedFields[i]["status"] = this.$i18n.messages[
            this.$i18n.locale
          ]["$vuetify"]["status"][items[i]["status"]];
        }
        //role
        if (items[i]["role"]) {
          translatedFields[i]["role_key"] = items[i]["role"];
          translatedFields[i]["role"] = this.$i18n.messages[this.$i18n.locale][
            "$vuetify"
          ]["manager_role"][items[i]["role"]];
        }
      }
      return translatedFields;
    },
    onResize() {
      if (window.innerWidth < 769) this.isMobile = true;
      else this.isMobile = false;
    }
  },
};
</script>

<style scoped>
.mobile {
  color: #333;
}

@media screen and (max-width: 768px) {
  .mobile table.v-table tr {
    max-width: 100%;
    position: relative;
    display: block;
  }

  .mobile table.v-table tr:nth-child(odd) {
    border-left: 6px solid deeppink;
  }

  .mobile table.v-table tr:nth-child(even) {
    border-left: 6px solid cyan;
  }

  .mobile table.v-table tr td {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #f5f5f5;
    height: auto;
    padding: 10px;
  }

  .mobile table.v-table tr td ul li:before {
    content: attr(data-label);
    padding-right: 0.5em;
    text-align: left;
    display: block;
    color: #999;
  }
  .v-datatable__actions__select {
    width: 50%;
    margin: 0px;
    justify-content: flex-start;
  }
  .mobile .theme--light.v-table tbody tr:hover:not(.v-datatable__expand-row) {
    background: transparent;
  }
}
.flex-content {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.flex-item {
  padding: 5px;
  width: 50%;
  height: 40px;
  font-weight: bold;
}

@media only screen and (max-width: 900px) {
  .companyName {
    display: none;
  }
  .companyNameTop {
    display: block;
  }
}
@media only screen and (min-width: 900px) {
  .companyNameTop {
    display: none;
  }
  .companyName {
    display: inline-block;
  }
}
.limitedText{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
.minHeight {
  min-height:550px;
}
</style>