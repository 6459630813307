<template>
  <v-dialog
    v-model="dialogState"
    persistent
    width="95vw"
    max-width="1200px"
    style="80vh"
  >
    <v-card>
      <v-toolbar color="accent">
        <v-toolbar-title style="width:100%">
          <v-container fluid>
            <v-row>
              <v-col class="col-xs-10 col-sm-10 col-md-10">
                <span v-if="messageToRead.type == 'sms'">
                  <v-icon class="iconFix mr-2">mdi-message-processing</v-icon>
                  SMS
                </span>

                <span v-if="messageToRead.type == 'email'">
                  <v-icon class="iconFix mr-2">mdi-at</v-icon> Email</span
                >

                <span v-if="messageToRead.type == 'inbox'">
                  <v-icon
                    class="iconFix mr-2"
                    v-if="messageToRead.type == 'inbox'"
                    >mdi-inbox-arrow-down</v-icon
                  >
                  Inbox</span
                >

                <span v-if="messageToRead.type == 1">{{
                  $vuetify.lang.t("$vuetify.status.system_message")
                }}</span>
              </v-col>
              <v-col
                class="col-xs-2 col-sm-2 col-md-2"
                style="text-align:right"
              >
                <v-icon
                  @click="
                    dialogState = false;
                    $emit('close');
                  "
                  id="closePopupBtn"
                  >mdi-close</v-icon
                >
              </v-col>
            </v-row>
          </v-container>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-title class="headline"></v-card-title>
      <v-card-text>
        <div v-if="this.messageToRead.type == 'inbox'">
          <div v-html="notificationData.body[$i18n.locale]"></div>
        </div>
        <div v-if="this.messageToRead.type == 'sms' || this.messageToRead.type == 'email'">
          <div v-if="this.notificationData.custom_text != null" v-html="notificationData.custom_text[$i18n.locale]"></div>
          <div v-if="this.notificationData.template.text != null" v-html="notificationData.template.text[$i18n.locale]"></div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: function() {
    return {
      dialogState: false,
      messageToRead: {
        type: this.$route.params.type,
      },
    };
  },
  computed: {
    loc() {
      return this.$i18n.locale;
    },
  },
  watch: {
    popupNotification: function(newVal) {
      this.dialogState = newVal;
    },
    $route(to) {
      this.messageToRead.type = to.params.type;
    },
  },
  props: ["notificationData", "popupNotification"],
};
</script>
