import vm from '@/main.js'
import * as HelperModel from '@/models/product.js'

class ProductHelpers {
    itemTypes() {
        const types = vm.$i18n.messages[vm.$i18n.locale]["$vuetify"][
            "transaction_types"
        ];
        var res = [];
        for (var t in types) {
            res.push({
                name: types[t],
                key: t
            });
        }
        return res
    }

    newItemModel() {
        return JSON.parse(JSON.stringify(HelperModel.newItem))
    }

    validatorModel() {
        return JSON.parse(JSON.stringify(HelperModel.validator))
    }

    validateForm(item, hasError=true) {
        var validator = this.validatorModel()
        validator.hasError = hasError;
        if (item.brand.ka === null || item.brand.ka.length <= 0) {
            validator.brand.ka.error_show = true;
            validator.brand.ka.error_msg = vm.$vuetify.lang.t(
                "$vuetify.errors.required"
            );
            validator.hasError = true;
        } else {
            validator.brand.ka.error_show = false;
            validator.brand.ka.error_msg = null;
            validator.hasError = false;
        }

        if (item.target_market === null || item.target_market.length <= 0) {
            validator.target_market.error_show = true;
            validator.target_market.error_msg = vm.$vuetify.lang.t(
                "$vuetify.errors.required"
            );
            validator.hasError = true;
        } else {
            validator.target_market.error_show = false;
            validator.target_market.error_msg = null;
            validator.hasError = validator.hasError ? true : false;
        }

        if (item.brand.en === null || item.brand.en.length <= 0) {
            validator.brand.en.error_show = true;
            validator.brand.en.error_msg = vm.$vuetify.lang.t(
                "$vuetify.errors.required"
            );
            validator.hasError = true;
        } else {
            validator.brand.en.error_show = false;
            validator.brand.en.error_msg = null;
            validator.hasError = validator.hasError ? true : false;
        }

        if (item.name.ka === null || item.name.ka.length <= 0) {
            validator.name.ka.error_show = true;
            validator.name.ka.error_msg = vm.$vuetify.lang.t(
                "$vuetify.errors.required"
            );
            validator.hasError = true;
        } else {
            validator.name.ka.error_show = false;
            validator.name.ka.error_msg = null;
            validator.hasError = validator.hasError ? true : false;
        }

        if (item.name.en === null || item.name.en.length <= 0) {
            validator.name.en.error_show = true;
            validator.name.en.error_msg = vm.$vuetify.lang.t(
                "$vuetify.errors.required"
            );
            validator.hasError = true;
        } else {
            validator.name.en.error_show = false;
            validator.name.en.error_msg = null;
            validator.hasError = validator.hasError ? true : false;
        }

        if (item.barcode_type === null) {
            validator.barcode_type.error_show = true;
            validator.barcode_type.error_msg = vm.$vuetify.lang.t(
                "$vuetify.errors.required"
            );
            validator.hasError = true;
        } else {

            if (item.barcode_type!=='ean8' && item.barcode_type!=='ean13' && item.barcode_packaging_level < 1 || item.barcode_packaging_level > 9) {
                validator.barcode_packaging_level.error_show = true;
                validator.barcode_packaging_level.error_msg = vm.$vuetify.lang.t(
                    "$vuetify.errors.required"
                );
                validator.hasError = true;
            } else {
                validator.barcode_packaging_level.error_show = false;
                validator.barcode_packaging_level.error_msg = null;
                validator.hasError = validator.hasError ? true : false;
            }

            if (item.barcode_type!=='ean8' && item.barcode_type!=='ean13' && item.barcode_parent < 1) {
                if(item.barcode == null) {
                    validator.barcode_parent.error_show = true;
                    validator.barcode_parent.error_msg = vm.$vuetify.lang.t(
                        "$vuetify.errors.required"
                    );
                    validator.hasError = true;
                }
            } else {
                validator.barcode_parent.error_show = false;
                validator.barcode_parent.error_msg = null;
                validator.hasError = validator.hasError ? true : false;
            }

            validator.barcode_type.error_show = false;
            validator.barcode_type.error_msg = null;
            validator.hasError = validator.hasError ? true : false;
        }


        if (item.type.ka === null || item.type.ka.length <= 0) {
            validator.type.ka.error_show = true;
            validator.type.ka.error_msg = vm.$vuetify.lang.t(
                "$vuetify.errors.required"
            );
            validator.hasError = true;
        } else {
            validator.type.ka.error_show = false;
            validator.type.ka.error_msg = null;
            validator.hasError = validator.hasError ? true : false;
        }

        if (item.type.en === null || item.type.en.length <= 0) {
            validator.type.en.error_show = true;
            validator.type.en.error_msg = vm.$vuetify.lang.t(
                "$vuetify.errors.required"
            );
            validator.hasError = true;
        } else {
            validator.type.en.error_show = false;
            validator.type.en.error_msg = null;
            validator.hasError = validator.hasError ? true : false;
        }

        if (item.package.ka === null || item.package.ka.length <= 0) {
            validator.package.ka.error_show = true;
            validator.package.ka.error_msg = vm.$vuetify.lang.t(
                "$vuetify.errors.required"
            );
            validator.hasError = true;
        } else {
            validator.package.ka.error_show = false;
            validator.package.ka.error_msg = null;
            validator.hasError = validator.hasError ? true : false;
        }

        if (item.package.en === null || item.package.en.length <= 0) {
            validator.package.en.error_show = true;
            validator.package.en.error_msg = vm.$vuetify.lang.t(
                "$vuetify.errors.required"
            );
            validator.hasError = true;
        } else {
            validator.package.en.error_show = false;
            validator.package.en.error_msg = null;
            validator.hasError = validator.hasError ? true : false;
        }

        if (item.volume_amount === null || item.volume_amount.length <= 0) {
            validator.volume_amount.error_show = true;
            validator.volume_amount.error_msg = vm.$vuetify.lang.t(
                "$vuetify.errors.required"
            );
            validator.hasError = true;
        } else {
            validator.volume_amount.error_show = false;
            validator.volume_amount.error_msg = null;
            validator.hasError = validator.hasError ? true : false;
        }

        if (item.volume_unit === null || item.volume_unit.length <= 0) {
            validator.volume_unit.error_show = true;
            validator.volume_unit.error_msg = vm.$vuetify.lang.t(
                "$vuetify.errors.required"
            );
            validator.hasError = true;
        } else {
            validator.volume_unit.error_show = false;
            validator.volume_unit.error_msg = null;
            validator.hasError = validator.hasError ? true : false;
        }

        if(!item.gtin_segment_code || item.gtin_segment_code == null) {
            validator.gtin_segment_code.error_show = true;
            validator.gtin_segment_code.error_msg = vm.$vuetify.lang.t(
                "$vuetify.errors.required"
            );
            validator.hasError = validator.hasError ? true : false;
        } else {
            validator.gtin_segment_code.error_show = false;
            validator.gtin_segment_code.error_msg = null;
            validator.hasError = validator.hasError ? true : false;
        }

        return validator;
    }
}

const ProductHelper = new ProductHelpers
export default ProductHelper