export const validator = {
    hasError: true,
    name_ka: {
        error_show: false,
        error_msg: null
    },
    name_en: {
        error_show: false,
        error_msg: null
    },
    address_ka: {
        error_show: false,
        error_msg: null
    },
    address_en: {
        error_show: false,
        error_msg: null
    },
    city_ka: {
        error_show: false,
        error_msg: null
    },
    city_en: {
        error_show: false,
        error_msg: null
    },
    city: {
        error_show: false,
        error_msg: null
    },
    main_field_ka: {
        error_show: false,
        error_msg: null
    },
    main_field_en: {
        error_show: false,
        error_msg: null
    },
    main_production_field: {
        error_show: false,
        error_msg: null
    },
    production_fields_ka: {
        error_show: false,
        error_msg: null
    },
    production_fields_en: {
        error_show: false,
        error_msg: null
    },
    id_code: {
        error_show: false,
        error_msg: null
    },
    manager_id: {
        error_show: false,
        error_msg: null
    },
    email: {
        error_show: false,
        error_msg: null
    },
    phone: {
        error_show: false,
        error_msg: null
    },
    fax: {
        error_show: false,
        error_msg: null
    },
    turnover: {
        error_show: false,
        error_msg: null
    },
    postal_code: {
        error_show: false,
        error_msg: null
    },
    prefix: {
        error_show: false,
        error_msg: null
    }
}

export const newItem = {
    name: {
        ka: null,
        en: null
    },
    address: {
        ka: null,
        en: null
    },
    city: null,
    main_production_field: null,
    production_fields: [],
    yearly_turnover: null,
    id_code: null,
    manager_id: null,
    postal_code: null,
    email: null,
    phone: null,
    fax: null,
    prefix: null,
    status: 'review'
}