import vm from '@/main.js'
import * as HelperModel from '@/models/company.js'

class CompanyHelpers {

    constructor() {
        this.regex = {
            digits: /^\d+$/,
            email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z0-9]{2,}))$/
        }
    }

    validatorModel() {
        return JSON.parse(JSON.stringify(HelperModel.validator))
    }

    newItemModel() {
        return JSON.parse(JSON.stringify(HelperModel.newItem))
    }

    validateForm(item) {
        var validator = this.validatorModel()

        if (item.name.ka === null || item.name.ka.length <= 0) {
            validator.name_ka.error_show = true;
            validator.name_ka.error_msg = vm.$vuetify.lang.t(
                "$vuetify.errors.required"
            );
            validator.hasError = true;
        } else {
            validator.name_ka.error_show = false;
            validator.name_ka.error_msg = null;
            validator.hasError = false;
        }

        if (item.name.en === null || item.name.en.length <= 0) {
            validator.name_en.error_show = true;
            validator.name_en.error_msg = vm.$vuetify.lang.t(
                "$vuetify.errors.required"
            );
            validator.hasError = true;
        } else {
            validator.name_en.error_show = false;
            validator.name_en.error_msg = null;
            validator.hasError = validator.hasError ? true : false;
        }

        if (Array.isArray(item.city)) {
            if (item.city.ka === null || item.city.ka.length <= 0) {
                validator.city_ka.error_show = true;
                validator.city_ka.error_msg = vm.$vuetify.lang.t(
                    "$vuetify.errors.required"
                );
                validator.hasError = true;
            } else {
                validator.city_ka.error_show = false;
                validator.city_ka.error_msg = null;
                validator.hasError = validator.hasError ? true : false;
            }

            if (item.city.en === null || item.city.en.length <= 0) {
                validator.city_en.error_show = true;
                validator.city_en.error_msg = vm.$vuetify.lang.t(
                    "$vuetify.errors.required"
                );
                validator.hasError = true;
            } else {
                validator.city_en.error_show = false;
                validator.city_en.error_msg = null;
                validator.hasError = validator.hasError ? true : false;
            }
        }

        if (!Array.isArray(item.city)) {
            if (item.city === null || item.city.length <= 0) {
                validator.city.error_show = true;
                validator.city.error_msg = vm.$vuetify.lang.t(
                    "$vuetify.errors.required"
                );
                validator.hasError = true;
            } else {
                validator.city.error_show = false;
                validator.city.error_msg = null;
                validator.hasError = validator.hasError ? true : false;
            }
        }

        if (item.address.ka === null || item.address.ka.length <= 0) {
            validator.address_ka.error_show = true;
            validator.address_ka.error_msg = vm.$vuetify.lang.t(
                "$vuetify.errors.required"
            );
            validator.hasError = true;
        } else {
            validator.address_ka.error_show = false;
            validator.address_ka.error_msg = null;
            validator.hasError = validator.hasError ? true : false;
        }

        if (item.address.en === null || item.address.en.length <= 0) {
            validator.address_en.error_show = true;
            validator.address_en.error_msg = vm.$vuetify.lang.t(
                "$vuetify.errors.required"
            );
            validator.hasError = true;
        } else {
            validator.address_en.error_show = false;
            validator.address_en.error_msg = null;
            validator.hasError = validator.hasError ? true : false;
        }

        if (Array.isArray(item.main_field)) {
            if (item.main_field.ka === null || item.main_field.ka.length <= 0) {
                validator.main_field_ka.error_show = true;
                validator.main_field_ka.error_msg = vm.$vuetify.lang.t(
                    "$vuetify.errors.required"
                );
                validator.hasError = true;
            } else {
                validator.main_field_ka.error_show = false;
                validator.main_field_ka.error_msg = null;
                validator.hasError = validator.hasError ? true : false;
            }

            if (item.main_field.en === null || item.main_field.en.length <= 0) {
                validator.main_field_en.error_show = true;
                validator.main_field_en.error_msg = vm.$vuetify.lang.t(
                    "$vuetify.errors.required"
                );
                validator.hasError = true;
            } else {
                validator.main_field_en.error_show = false;
                validator.main_field_en.error_msg = null;
                validator.hasError = validator.hasError ? true : false;
            }
        }


        if (!Array.isArray(item.main_production_field)) {
            if (item.main_production_field === null || item.main_production_field.length <= 0) {
                validator.main_production_field.error_show = true;
                validator.main_production_field.error_msg = vm.$vuetify.lang.t(
                    "$vuetify.errors.required"
                );
                validator.hasError = true;
            } else {
                validator.main_production_field.error_show = false;
                validator.main_production_field.error_msg = null;
                validator.hasError = validator.hasError ? true : false;
            }
        }

        if (item.email === null || item.email.length <= 0 || !this.regex.email.test(item.email)) {
            validator.email.error_show = true;
            validator.email.error_msg = vm.$vuetify.lang.t(
                "$vuetify.errors.required"
            );
            validator.hasError = true;
        } else {
            validator.email.error_show = false;
            validator.email.error_msg = null;
            validator.hasError = validator.hasError ? true : false;
        }

        if (item.id_code === null || item.id_code.length <= 0 || !this.regex.digits.test(item.id_code)) {
            validator.id_code.error_show = true;
            validator.id_code.error_msg = vm.$vuetify.lang.t(
                "$vuetify.errors.required"
            );
            validator.hasError = true;
        } else {
            validator.id_code.error_show = false;
            validator.id_code.error_msg = null;
            validator.hasError = validator.hasError ? true : false;
        }

        // if (item.manager_id === null || item.manager_id.length <= 0 || !this.regex.digits.test(item.manager_id)) {
        //     validator.manager_id.error_show = true;
        //     validator.manager_id.error_msg = vm.$vuetify.lang.t(
        //         "$vuetify.errors.required"
        //     );
        //     validator.hasError = true;
        // } else {
        //     validator.manager_id.error_show = false;
        //     validator.manager_id.error_msg = null;
        //     validator.hasError = validator.hasError ? true : false;
        // }


        return validator;
    }

}

const CompanyHelper = new CompanyHelpers
export default CompanyHelper